// material-ui
import { styled } from '@mui/material/styles';

// project-imports
// import { ThemeDirection } from 'config';

// ==============================|| CALENDAR - STYLED ||============================== //

const ExperimentalStyled = styled('div')(({ theme }) => ({
    width: 'calc(100% + 2px)',
    marginLeft: -1,
    marginBottom: '30px',

    // Hide license message
    '& .fc-license-message': {
        display: 'none'
    },
    '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
        overflow: 'hidden !important'
    },

    // Basic style
    '& .fc': {
        '--fc-bg-event-opacity': 1,
        '--fc-border-color': theme.palette.divider,
        '--fc-daygrid-event-dot-width': '8px',
        '--fc-list-event-dot-width': '8px',
        '--fc-event-border-color': theme.palette.primary.dark,
        '--fc-now-indicator-color': theme.palette.error.main,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.75rem'  // Smaller text for compact layout
    },

    // Date text adjustments
    '& .fc .fc-daygrid-day-top': {
        display: 'grid',
        padding: '2px 0',  // Reduced padding for tighter cells
        '& .fc-daygrid-day-number': {
            textAlign: 'center',
            marginTop: 6,
            marginBottom: 6,
            fontSize: '0.8rem'  // Smaller date font size
        }
    },

    // Weekday header styling
    '& .fc .fc-col-header-cell': {
        backgroundColor: theme.palette.grey[100],
        padding: '4px 0'  // Reduced padding in header
    },
    '& .fc .fc-col-header-cell-cushion': {
        color: theme.palette.grey[900],
        padding: '8px',  // Reduced padding for a compact look
        fontSize: '0.85rem'
    },

    // Event styling
    '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
        marginLeft: 2,
        marginBottom: 4,
        borderRadius: 4,
        backgroundColor: theme.palette.error.main,
        border: 'none'
    },
    '& .fc-h-event .fc-event-main': {
        padding: 2,
        paddingLeft: 6,
        fontSize: '0.7rem'  // Smaller event text
    },

    // Popover for multiple events
    '& .fc .fc-more-popover': {
        border: 'none',
        borderRadius: 6,
        zIndex: 1200,
    },
    '& .fc .fc-more-popover .fc-popover-body': {
        backgroundColor: theme.palette.grey[200],
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    '& .fc .fc-popover-header': {
        padding: 8,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary
    },


    // Agenda and day view adjustments
    '& .fc-theme-standard .fc-list-day-cushion': {
        backgroundColor: theme.palette.grey[100],
    },
    '& .fc .fc-day': {
        cursor: 'pointer',
    },
    '& .fc .fc-timeGridDay-view .fc-timegrid-slot': {
        backgroundColor: theme.palette.background.paper,
    },
    '& .fc .fc-timegrid-slot': {
        cursor: 'pointer',
    },
    '& .fc .fc-list-event:hover td': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
    },
    '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link': {
        padding: 4,
        margin: 1
    },
}));

const CalendarStyled = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: '30px',

    // Hide license message
    '& .fc-license-message': {
        display: 'none'
    },
    '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
        overflow: 'hidden !important'
    },

    // Event styling
    '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
        marginLeft: 2,
        marginBottom: 4,
        borderRadius: 4,
        backgroundColor: theme.palette.error.main,
        border: 'none'
    },
    '& .fc-h-event .fc-event-main': {
        padding: 2,
        paddingLeft: 6,
        fontSize: '0.7rem'  // Smaller event text
    },

    // Popover for multiple events
    '& .fc .fc-more-popover': {
        border: 'none',
        borderRadius: 6,
        zIndex: 1200,
    },
    '& .fc .fc-more-popover .fc-popover-body': {
        backgroundColor: theme.palette.grey[200],
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    '& .fc .fc-popover-header': {
        padding: 8,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary
    },

    // FullCalendar styles
    '& .fc': {
        '--fc-bg-event-opacity': 1,
        '--fc-border-color': theme.palette.divider,
        '--fc-daygrid-event-dot-width': '8px',
        '--fc-list-event-dot-width': '8px',
        '--fc-event-border-color': theme.palette.primary.dark,
        '--fc-now-indicator-color': theme.palette.error.main,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.75rem',
    },

    // Day cells styling for square shape
    '@media (max-width: 600px)': {
        '& .fc .fc-daygrid-day': {
            flexBasis: 'calc(100% / 7)', // Make cells take equal width
            maxWidth: 'calc(100% / 7)',  // Limit max width to keep cells square
            aspectRatio: '1 / 1',        // Maintain square shape for each cell
        },
    },

    // Weekday header styling for mobile
    '& .fc .fc-col-header-cell': {
        backgroundColor: theme.palette.grey[100],
        padding: '4px 0',
        fontSize: '0.85rem',
    },
}));



export default CalendarStyled;
