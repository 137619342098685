import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DloFeatures from './DloFeatures'
import DloFaq from './DloFaq'
import DloGettingReady from './DloGettingReady'
import SafeToDrive from './SafeToDrive'

const DrivingLessonOnline = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>

      <Header />
      <SafeToDrive />
      <DloGettingReady />
      <DloFeatures />
      <DloFaq />
      <Footer />

    </>
  )
}

export default DrivingLessonOnline
