
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { Typography,Stack } from '@mui/material';

const LoaderBackdrop = ({ open, color = "inherit" }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Stack spacing={2} justifyContent={'center'}>
            <CircularProgress color={color} />
            <Typography>Please wait</Typography>
            </Stack>
           
        </Backdrop>
    );
};

LoaderBackdrop.propTypes = {
    open: PropTypes.bool.isRequired, 
    color: PropTypes.string,        
};

export default LoaderBackdrop;
