import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbar = ({ children, style }) => (
  <Scrollbars
    autoHide={false} // Keeps the scrollbar always visible
    renderTrackVertical={({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          width: '8px', // Customize the width of the scrollbar track
          right: 0,
          bottom: 0,
          top: 0,
          borderRadius: '4px',
          backgroundColor: '#f0f0f0', // Track background color
        }}
      />
    )}
    renderThumbVertical={({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          backgroundColor: '#008080', // Custom thumb color
          borderRadius: '4px',
        }}
      />
    )}
    renderThumbHorizontal={({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          backgroundColor: '#008080', // Custom thumb color
          borderRadius: '4px',
        }}
      />
    )}
    style={{ ...style, height: '100%', width: '100%' }}
  >
    {children}
  </Scrollbars>
);

export default CustomScrollbar;
