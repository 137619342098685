import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import MainPage from './MainPage'


const CancellationPolicy = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])

  return (

    <>
      <Header />
      <MainPage />
      <Footer />
    </>

  )
}

export default CancellationPolicy
