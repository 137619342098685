import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'


const styles = {
  whyChoose: {
    display:'grid',
    gridTemplateColumns:'repeat(4, 23%)',
    gap:'10px',
    justifyContent:'center',

    '@media (max-width: 900px)': {
        gridTemplateColumns:'repeat(2, 49%)',
    },
    '@media (max-width: 600px)': {
      gridTemplateColumns:'repeat(1, 99%)',
  },
  },
}



const HomeWhyChoose = () => {


  const items = [
    { title: 'Flexible Options', paragraph: 'Convenient scheduling and remote learning choices tailored to you.' },
    { title: 'Comprehensive Services', paragraph: 'All-encompassing support for diverse driver education needs.' },
    { title: 'Certified Curriculum', paragraph: 'Officially recognized course content meeting state requirements.' },
    { title: 'Preventing Future Instances', paragraph: 'Equipping drivers with skills to avoid repeat offenses.' },
  ];

  return (
    <>

        <Box  sx={{}} className="why_choose_bg">

        <Container>
        
        <Grid item xs={12} sx={{}}>
            <Typography variant="h4" sx={{ pt:'2', color:'#fff !important',  }}>
                Why Choose 1ACT Driving Schools?
            </Typography>
        </Grid>

        <Grid container spacing={4} sx={{justifyContent:"center",marginTop:'50px'}}>

          <Grid sx={styles.whyChoose}> 
          {items.map((item, index) => (
           
            <Grid className='why_chs_hvr' item key={index} sx={{
              padding:'20px!important',
              background:'#ffffff1c',
              border:'1px solid #49545d',
            }}>
              <Typography variant="h6" sx={{color:'#fff !important',margin:'0px 0px 10px'}}>{item.title}</Typography>
              <Typography sx={{color:'#fff !important',fontSize:'14px !important',}}>{item.paragraph}</Typography>
            </Grid>
          ))}
          </Grid>

        </Grid>


        </Container>


        </Box>    

      
    </>
  )
}

export default HomeWhyChoose;
