import React from 'react';
import demo from '../../assets/1actLogo.png';
import { Stack, Button, Typography, Toolbar, Box, AppBar, Avatar, IconButton, Badge, Tooltip } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import user from '../../assets/user.jpg'
const Navbar = () => {

    const userInfoString = localStorage.getItem('student_info');
    const userInfo = JSON.parse(userInfoString);
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
                    <Toolbar>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <img
                                    src={demo}
                                    alt='logo'
                                    style={{
                                        width: '150px',
                                        height: '80px',
                                        // objectFit: 'contain'
                                    }}
                                />
                             
                            </Stack>
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack direction='row' spacing={2} alignItems='center' sx={{ cursor: 'pointer' }}>
                                <Tooltip title='Favorites'>
                                    <IconButton
                                        color="secondary"
                                        variant="light"
                                        sx={{ color: 'text.primary' }}

                                    >
                                        <Badge badgeContent={'3'} color="error">
                                            <FavoriteBorderIcon sx={{ pt: 0.4 }} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip >

                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <Avatar alt="Remy Sharp" sx={{ bgcolor: 'orange', width: '26px', height: '26px' }} sizes='small' src={user} />
                                    <Typography sx={{ color: 'black' }} variant='body2'>{userInfo?.firstname}</Typography>
                                </Stack>

                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
};

export default Navbar;
