import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LoginPage from './LoginPage'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Stack, Typography } from '@mui/material';
import bannerbg from "../../assets/images/home/banner-bg.png";
const Login = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/', {
      state: { number: 2 }
    })
  }, [])

  return (
    <>
      {/* Blur background wrapper */}


      <Header />
      {/* <LoginPage />
      <Footer /> */}
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {/* Background content with blur */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url(bannerbg)', // Replace with your background image URL
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(20px)', // Apply blur effect
            zIndex: -1
          }}
        ></div>

        {/* Spinner and text */}
        <Stack spacing={2} alignItems="center">
          <CircularProgress size="10rem" />
          <Typography variant="h6" color="textPrimary">
            Loading... Please Wait
          </Typography>
        </Stack>
      </div>
    </>
  )
}

export default Login
