import React, { useEffect } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import RtBanner from './RtBanner';
import RtFeatures from './RtFeatures';
import RtFaq from './RtFaq';
import RtStartTest from './RtStartTest';
import RtOfferGreen from './RtOfferGreen';


const RoadTesting = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <div>

        <Header />
        <RtBanner />
        <RtStartTest />
        <RtOfferGreen />
        <RtFeatures />
        <RtFaq />
        <Footer />
      
    </div>
  )
}

export default RoadTesting;
