
import MainRoutes from "./Routes/MainRoutes";

import { HelmetProvider } from 'react-helmet-async';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { SnackbarProvider } from "notistack";
// ..
AOS.init();


function App() {
  return (

    <>
      <HelmetProvider>
        <SnackbarProvider>
          <MainRoutes />
        </SnackbarProvider>
      </HelmetProvider>
    </>

  );
}

export default App;
