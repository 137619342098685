import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'

import footban2 from "../../assets/images/home/footban2.jpg"
import victimImpact from "../../assets/images/home/victimIpact.png"

const styles = {

    CancellationPolicyHeder: {
        background:`url(${footban2}) no-repeat top center`,backgroundSize:'100% 100%',padding:'160px 0px 60px',
        '@media (max-width: 900px)': {
          padding: '50px 0px 50px',
        }
    },

    cancellationHeading:{
        color:'var(--primary) !important',
    },
    faqRight: {

        '@media (max-width: 900px)': {
            margin:'auto',
    
        },
      },
      faqRightImage: {
        width:'80%',marginRight:'auto', marginY:'30px',
        '@media (max-width: 900px)': {
          width:'100%'
        }
      },

}

const VictimMain = () => {
  return (

    <>

    <Box>

        <Box sx={styles.CancellationPolicyHeder}>
            <Container>
                <Typography variant='h4' sx={{color:'#fff !important',}}>VICTIM IMPACT PANEL</Typography>
            </Container>
        </Box>

        <Box sx={{py:'60px'}}>
            <Container>

                <Grid container spacing={2} sx={{display:'flex',alignItems:'center',}}>

                    <Grid item md={6}>
                        <Grid data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={victimImpact} sx={styles.faqRightImage}/>
                        </Grid>
                    </Grid>

                    <Grid item md={6}>
                        <Grid>
                            <Typography component='f22w500' sx={{display:'flex', alignItems:'baseline', p:'10px 0px 16px' }}>
                                WHAT IS VICTIM IMPACT PANEL CLASS?
                            </Typography>

                            <Typography sx={{textAlign:'justify', py:'10px'}}>The Victim Impact Panel class is designed to help drunk and drugged driving offenders to realize how their decisions impact the lives of those around them. In the class, real life victims of substance-impaired drivers and surviving loved ones, share their real first-person accounts of how the crash impacts their lives.  The victims and survivors do not judge, they just simply tell their stories in such a way to help substance-impaired offenders empathize and understand the tragedy.</Typography>
                            <Typography sx={{textAlign:'justify',py:'10px'}}>This is a great class even for those who haven't been arrested for driving under the influence.  Also, more and more lawyers are recommending that their clients take this class even if it is not court ordered to do so.</Typography>
                            <Typography sx={{textAlign:'justify',py:'10px'}}>1Act Driving Schools does not currently offer this class, but you can follow the link below to the <a target='_blank' href="https://maddvip.org/">Madd website</a> to learn more and register.</Typography>
                        </Grid>
                    </Grid>

                </Grid>

            </Container>
        </Box>





    </Box>
      
    </>
  )
}

export default VictimMain
