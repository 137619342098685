import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LBanner from './LBanner'
import LwhoAre from './LwhoAre'
import LOurLocations from './LOurLocations'
import LContact from './LContact'

const Locations = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>

      <Header />
      <LBanner />
      <LContact />
      <LwhoAre />
      <LOurLocations />
      <Footer />

    </>
  )
}

export default Locations
