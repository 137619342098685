import React from 'react'
import Navbar from './NavBarss'

const Dashboard = () => {
    return (
        <div>
            <Navbar />
        </div>
    ) 
}

export default Dashboard