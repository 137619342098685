import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import journeyImage1 from "../../assets/images/home/journey1.png";
import journeyImage2 from "../../assets/images/home/journey2.png";
import journeyImage3 from "../../assets/images/home/journey3.png";

const cardsData = [
  {
    image: journeyImage1,
    title: "Choose your course",
    description:
      "Whether you need Drivers Ed, Defensive Driving, or DUI/Risk Reduction, find the right fit for your needs on our website.",
  },
  {
    image: journeyImage2,
    title: "Register & assess",
    description:
      "Register for your preferred course, take the lessons and assessment to test your knowledge.",
  },
  {
    image: journeyImage3,
    title: "Learn & succeed",
    description:
      "Take your chosen course in-person or online with certified instructors. Complete the program and receive your official certificate.",
  },
];

const HomeJourney = () => {
  return (
    <Container sx={{ py: "50px" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography>Gear Up For The Road Ahead</Typography>
          <Typography variant="h4" sx={{ pt: 2 }}>
            Fuel Your Journey Towards Driving Excellence
          </Typography>
        </Grid>

        {cardsData.map((card, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} sx={{textAlign:'center',}}>
            <Card className="card" >
              <CardMedia
                className="card-img-top"
                component="img"
                height="100%"
                alt="Product Image"
                sx={{ borderRadius: 2 }}
                src={card.image}
              />

              <div className="blur"></div>

              <CardContent className="product-detail">
                <Typography
                  variant="h6"
                  className="card-title"
                  sx={{
                    color: "#fff !important",
                    fontSize: "18px !important",
                    margin: "0px 0px 8px",
                  }}
                >
                  {card.title}
                </Typography>

                <Typography
                  className="descrip"
                  sx={{
                    color: "#fff !important",
                    fontSize: "12px !important",
                    mt: "10px",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomeJourney;
