import React, { useEffect, useState } from 'react';
import { Stack, Button, Grid, TextField, InputAdornment, InputLabel, Typography, Autocomplete, CircularProgress, Checkbox, Box } from '@mui/material';
import { CheckOutlined } from '@ant-design/icons';
import cvv from '../../assets/cvv.png';
import master from '../../assets/master-card.png';
import MainCard from '../../components/MainCard';
import { Toaster, toast } from "react-hot-toast";
import visa from '../../assets/checkout/purepng.com-visa-logologobrand-logoiconslogos-251519938794uqvcz.png'
import visa2 from '../../assets/checkout/American-Express-Color.png'
import visa3 from '../../assets/checkout/Apple_Pay-Logo.wine.png'
import visa4 from '../../assets/checkout/Discover-logo.png'
import visa5 from '../../assets/checkout/Mastercard-Logo-2016-2020.png'
import visa6 from '../../assets/checkout/jcb_card_payment-512.webp'
import visa7 from '../../assets/checkout/paypal-icon-2048x1286-ha8kzb8k.png'
import visa8 from '../../assets/checkout/2166341.webp'
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TermsAndCondition from './TermsAndCondition';
import { googleTagForNewUserCardDetails } from '../googleanalytics/googleanalytics';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const PaymentPage = ({ onClickSubmit, setActiveStep, coursedetails, paymentDetails, setPaymentDetails, formData, setFormData, selected, setSelected, loaderopen }) => {


    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }


    const [errors, setErrors] = useState({});

    const validateCardNumber = (number) => {
        const regex = /^\d{15,16}$/;
        return regex.test(number);
    };

    const validateExpiryDate = (month, year) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100;

        if (!month || !year) return false;
        if (month < 1 || month > 12) return false;
        if (year < currentYear || (year === currentYear && month < currentMonth)) return false;
        return true;
    };

    const validateCVV = (cvv) => {
        const regex = /^\d{3,4}$/; // CVV should be 3 or 4 digits
        return regex.test(cvv);
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation logic for individual fields
        if (name === 'cvvNumber') {
            if (value.length > 4) {
                return;
            }
        }
        if (name === 'expiryMonth' || name === 'expiryYear') {
            if (value.length > 2) {
                return;
            }
        }

        if (name === 'cardNumber') {
            if (value.length > 19) {
                return;
            }
        }

        setPaymentDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };



    const [check, setCheck] = useState(false)

    const handleSubmit = () => {


        const { cardNumber, expiryMonth, expiryYear, cvvNumber } = paymentDetails;
        const newErrors = {};

        // Validate each field and collect errors
        if (!validateCardNumber(cardNumber)) {
            newErrors.cardNumber = 'Invalid card number. It must be 16 digits.';
            failure('Invalid card number.Please check and try again.');
            return;
        }
        if (!validateExpiryDate(expiryMonth, expiryYear)) {
            newErrors.expiryDate = 'Invalid expiry date. Please check and try again.';
            failure('Invalid expiry date. Please check and try again.');
            return;
        }
        if (!validateCVV(cvvNumber)) {
            newErrors.cvvNumber = 'Invalid CVV. It must be 3 or 4 digits.';
            failure('Invalid CVV.Please check and try again.');
            return;
        }

        setErrors(newErrors);

        if (check == false) {
            return toast.error('please check terms and conditions to proceed')
        }

        // if (Object.keys(newErrors).length === 0) {
        onClickSubmit();
        // }
    };

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handle = () => {
        if (roadTest) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    };

    const getImage = () => {
        return <img src={master} alt="card" />;
    };

    const navigate = useNavigate()




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setPaymentDetails({
            ...paymentDetails,
            [name]: value,
        });
    };

    // terms and condition in popup
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // terms and condition in popup


    // city , state , country
    const [countryInfo, setCountryInfo] = useState([]);
    const [countryLoading, setCountryLoading] = useState(true)
    const fetchCountryInfo = async () => {
        try {
            const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/iso`);
            const data = response?.data?.data;
            const result = data.map((item) => ({
                id: item.Iso2,
                label: item.name
            }));

            setCountryInfo(result);
            setCountryLoading(false)
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };


    const [stateInfo, setStateInfo] = useState([]);
    const [stateLoading, setStateLoading] = useState(false)
    const fetchState = async (id) => {

        try {
            setStateInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
                "country": id
            }
            );
            const data = response?.data?.data?.states;
            const result = data.map((item) => ({
                id: item.state_code,
                label: item.name
            }));
            setStateLoading(false)
            setStateInfo(result);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };



    const [cityInfo, setCityInfo] = useState([]);
    const [cityLoading, setCityLoading] = useState(false)

    const fetchCity = async (selectedStateId, country) => {

        try {
            setCityInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
                "country": `${country}`,
                "state": `${selectedStateId}`
            });
            const data = response.data.data;
            const result = data?.map((item) => ({
                id: item,
                label: item
            }));
            setCityInfo(result);
            setCityLoading(false)
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchCountryInfo()
        setFormData({
            ...formData,
            ['newstate']: 'Georgia',
        });
    }, []);


    useEffect(() => {


        if (formData?.newcountry) {
            fetchState(formData?.newcountry)
        }

        if (formData?.newcountry && formData?.newstate) {
            fetchCity(formData?.newstate, formData?.newcountry)
        }
    }, [formData?.newcountry]);

    // city , state , country

    return (
        <div>

            <Toaster
            // toastOptions={{
            //     duration: 3000,
            //     style: {
            //         cursor: 'pointer',
            //     },
            //     // Clickable setting ensures that clicking the toast will close it
            //     ariaLive: 'assertive',
            //     position: 'top-center',
            // }}
            />


            {/* <Box>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Card Number *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                name="cardNumber"
                                value={paymentDetails?.cardNumber}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (/^\d{0,16}$/.test(value)) {
                                        setPaymentDetails((prev) => ({
                                            ...prev,
                                            cardNumber: value,
                                        }));
                                    }
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>CardHolder Name *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                value={paymentDetails?.cardholderfirstname}
                                onChange={(e) =>
                                    setPaymentDetails((prev) => ({
                                        ...prev,
                                        cardholderfirstname: e?.target?.value,
                                    }))
                                }
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Stack spacing={1}>
                                    <InputLabel>Expiry Date *</InputLabel>
                                    <Grid container>
                                        <Grid item xs={6} sx={{ marginRight: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="MM"
                                                value={paymentDetails?.expiryMonth}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryMonth: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="YY"
                                                value={paymentDetails?.expiryYear}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryYear: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1}>
                                    <InputLabel>CVV *</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="password"
                                        value={paymentDetails?.cvvNumber}
                                        onChange={(e) => {
                                            const value = e?.target?.value;
                                            if (/^\d{0,4}$/.test(value)) {
                                                setPaymentDetails((prev) => ({
                                                    ...prev,
                                                    cvvNumber: value,
                                                }));
                                            }
                                        }}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Billing ZIP Code *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="cardzipcode"
                                value={paymentDetails?.cardzipcode}
                                onChange={handleChange2}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>Accepted Cards:</Typography>
                            <img src={visa} alt="Visa" width={50} />
                            <img src={visa2} alt="Visa 2" width={60} />
                            <img src={visa4} alt="Visa 4" width={60} />
                            <img src={visa5} alt="Visa 5" width={50} />
                        </Stack>
                    </Grid>

                </Grid>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox checked={check} onClick={() => setCheck(!check)} />
                    <Typography>
                        I have read and agree to the Cancellation Policy in{' '}
                        <Typography
                            component="span"
                            sx={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(true)}
                        >
                            terms and conditions
                        </Typography>
                    </Typography>
                </Stack>
            </Box> */}

            <Box>
                <Grid container spacing={2}>
                    {/* Card Number Section */}
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Card Number *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                name="cardNumber"
                                value={paymentDetails?.cardNumber}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (/^\d{0,16}$/.test(value)) {
                                        setPaymentDetails((prev) => ({
                                            ...prev,
                                            cardNumber: value,
                                        }));
                                    }
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>CVV *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="password"
                                value={paymentDetails?.cvvNumber}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (/^\d{0,4}$/.test(value)) {
                                        setPaymentDetails((prev) => ({
                                            ...prev,
                                            cvvNumber: value,
                                        }));
                                    }
                                }}
                            />
                        </Stack>
                    </Grid>


                    {/* Expiry and CVV Section */}
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0.4}>
                                    <InputLabel>Expiry Date *</InputLabel>
                                    <Grid container spacing={0.6}>
                                        <Grid item xs={6} >
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="MM"
                                                value={paymentDetails?.expiryMonth}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryMonth: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="YY"
                                                value={paymentDetails?.expiryYear}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryYear: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Billing ZIP Code *</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="cardzipcode"
                                        value={paymentDetails?.cardzipcode}
                                        onChange={handleChange2}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Cardholder Name and Zip Code Section */}
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Name as on Card *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                placeholder='First Name'
                                value={paymentDetails?.cardholderfirstname}
                                onChange={(e) =>
                                    setPaymentDetails((prev) => ({
                                        ...prev,
                                        cardholderfirstname: e?.target?.value,
                                    }))
                                }
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Stack spacing={1} sx={{mt:3.8}}>
                            {/* <InputLabel></InputLabel> */}
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                placeholder='Last Name'
                                value={paymentDetails?.cardholderlastname}
                                onChange={(e) =>
                                    setPaymentDetails((prev) => ({
                                        ...prev,
                                        cardholderlastname: e?.target?.value,
                                    }))
                                }
                            />
                        </Stack>
                    </Grid>



                    {/* Accepted Cards */}
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>Accepted Cards:</Typography>
                            <img src={visa} alt="Visa" width={50} />
                            <img src={visa2} alt="Visa 2" width={60} />
                            <img src={visa4} alt="Visa 4" width={60} />
                            <img src={visa5} alt="Visa 5" width={50} />
                        </Stack>
                    </Grid>
                </Grid>

                {/* Terms and Conditions */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox checked={check} onClick={() => setCheck(!check)} />
                    <Typography>
                        I Have read and agree to the Cancellation Policy in{' '}
                        <Typography
                            component="span"
                            sx={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(true)}
                        >
                            terms and conditions
                        </Typography>
                    </Typography>
                </Stack>

                {/* Modal for Terms and Conditions */}
                {/* <TermsAndCondition open={open} setOpen={setOpen} /> */}
            </Box>

            <TermsAndCondition open={open} setOpen={setOpen} />




            <Stack justifyContent="space-between" direction="row" sx={{ mt: 3 }}>
                <Button variant="contained" onClick={handle}>Back</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loaderopen}>
                    Submit
                </Button>
            </Stack>
        </div>
    );
}

export default PaymentPage;
