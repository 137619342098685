import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RlFaqLft from "../../assets/images/roadTesting/RlFaqLft.png";


const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
}

const drivingLessonsFaq = [
  {
    question: 'What is a Third Party Testing Program or Tester?',
    answer: ' A Third Party Testing Program or Tester is a Driver Training School specially licensed by the Georgia Department of Driver Services to administer the driver’s license road skills test to students who successfully complete an approved method of driver education',
  },
  {
    question: 'What are some road skills tested in Georgia?',
    answer: " The Georgia driver's license road test includes skills like backing up, stopping for signs and traffic signals, and yielding right-of-way.",
  },
  {
    question: "Who conducts the road test at 1Act?",
    answer: ' A certified driving instructor that is also certified as a third party tester is the person who conducts the road test.',
  },
  {
    question: "Where does the on-site road test take place?",
    answer: ' We currently have two certified testing locations which are the Lawrenceville office and Alpharetta office. ',
  },
  {
    question: "What is the benefit of taking a road test with 1Act?",
    answer: 'There are a couple benefits of taking your road test with 1Act such as sooner appointments, no wait time, use of our vehicle, and instructed by a well experienced driving instructor/examiner.',
  },
  {
    question: "What are the requirements to test and how can I schedule an appointment?",
    answer: 'Please check our page and review the testing requirements based on your age. Once you have purchased your on-site road test and have met the requirements, then you may contact our office to go over availability and book an appointment.',
  },
  // {
  //   question:"Are there any additional fees that I need to pay?",
  //   answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
  // },
]


const RtFaq = () => {

  return (
    <>
      <Box sx={{ py: "50px", position: "relative", }}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={RlFaqLft} sx={styles.faqRightImage} />
              </Grid>

            </Grid>
            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default RtFaq;
