import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import drivingLessonsfaq from "../../assets/images/driving-lesson/drivingLessonsfaq.png";
import { useNavigate } from 'react-router-dom';
const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    }
  }
}

const drivingLessonsFaq = [
  {
    question: 'Can lessons be shorter than 2 hours?',
    answer: 'Lessons are 2 hours long. Unfortunately, lessons cannot be less than or more than 2 hours.',
  },
  {
    question: 'Are lessons one on one training?',
    answer: ' Yes, driving lessons are one on one training. ',
  },
  {
    question: "Can a friend or parent/guardian sit in my lesson?",
    answer: ' Unfortunately, we cannot allow friends or parents/guardians in the vehicle during the lesson',
  },
  {
    question: "Are all your driver’s certified by the state and background checked?",
    answer: ' Yes, all our instructors are required to be certified by GA Department of Driver services. In addition, instructors have passed a background check and drug screening. ',
  },
  {
    question: "Is pick up and drop off offered? ",
    answer: 'Yes, we offer pick up and drop off to any location within 15 miles from our office. You may also request to meet your instructor at one of our offices',
  },
  {
    question: "When are lessons offered?",
    answer: 'We offer lessons seven days a week with schedules during the day, night and on weekends.',
  },
  {
    question: "What is taught during lessons?",
    answer: 'We will teach you how to properly park, parallel park, back up in a straight line, controlled left-hand turns, controlled right-hand turns. major intersection crossings, changing lanes, traffic merging, and freeway driving techniques.',
  },
]


const DrivingLessonsFaq = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/privacy-policy-cancellation-policy'); // Replace '/policy' with your actual policy route
  };
  return (
    <>
      <Box sx={{ py: "50px", position: "relative", background: '#E0EFFF', }}>
        <div className="DrivingLessonsFaq_ani"></div>

        <Container sx={{ border: '1px solid var(--primary)', padding: '30px 40px', borderRadius: '10px', background: 'var(--lightblue)', marginBottom: '50px' }}>
          <Typography variant="p" sx={{ pt: 2, pb: 2, fontWeight: '400 !important' }}>
            *<b>Cancellations must be made more than 48 hours in advance. Cancellations made between 48 hours or less in advance will incur a late fee of $60 . No shows and/or early termination of a lesson on behalf of a client will result in a loss of the remaining lesson time or entire lesson, and the session will not be refundable . Please see our</b>
            &nbsp; <span onClick={handleNavigation} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            > Refund policy </span><b>for further details.</b>
          </Typography>
        </Container>

        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia
                  className="hmeslidelft"
                  component="img"
                  alt="faq Image"
                  src={drivingLessonsfaq}
                  sx={styles.faqRightImage}
                />
              </Grid>

            </Grid>
            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DrivingLessonsFaq;
