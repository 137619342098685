import React, { useState, useRef, useEffect } from 'react';
import { Stack, TextField, IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IoMdCloseCircleOutline } from "react-icons/io";

const FitnessFileType = ({ doctypeid, file, onDocChange }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const fileInputRef = useRef(null);

    useEffect(() => {
        if (file) {
            const url = URL.createObjectURL(file);
            setFileUrl(url);
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [file]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            onDocChange(doctypeid, selectedFile);
        }
    };

    const handleEditClick = () => fileInputRef.current.click();

    const handleViewClick = () => setDialogOpen(true);

    const handleRemoveClick = () => {
        onDocChange(doctypeid, null);
        setFileUrl('');
        setDialogOpen(false);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleCloseDialog = () => setDialogOpen(false);
    const isPdf = file && file.type === 'application/pdf';

    return (
        <div>
            <Stack>
                <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />

                <TextField
                    size='small'
                    placeholder="Select Document"
                    value={file ? file.name : ''}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <>
                                <IconButton onClick={handleEditClick}>
                                    <CameraAltIcon />
                                </IconButton>
                                {file && (
                                    <>
                                        <IconButton onClick={handleViewClick}>
                                            <VisibilityIcon style={{ color: 'blue' }} />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        ),
                    }}
                />
            </Stack>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <Stack sx={{ float: 'right' }}>
                        <IconButton sx={{ fontSize: '30px', color: 'red' }} variant="outlined" color='error' onClick={handleCloseDialog}  >
                            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                        textAlign: 'center',
                    }}
                >
                    {isPdf ? (
                        <object
                            data={fileUrl}
                            type="application/pdf"
                            width="80%"
                            height="500"
                            aria-label="PDF preview"
                        >
                            <p>Alternative text - include a link to the PDF!</p>
                        </object>
                    ) : (
                        <img
                            src={fileUrl}
                            alt="Preview"
                            style={{ width: '100%', height: 'auto', maxHeight: '500px' }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FitnessFileType;
