import React from 'react'
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";


import circumstances from "../../assets/images/ClinicalEvaluation/CeFeaturesRht.png";
import tick from "../../assets/images/driving-lesson/tick.png"

import PlaceIcon from '@mui/icons-material/Place';
import LocationCard from '../../components/LocationCard';

const styles = {
  locations1: {
    display: 'flex',
    '@media (max-width: 900px)': {
      flexDirection: 'column'
    }
  }
}


const CePackages = () => {
  return (
    <>
      <Box sx={{ py: "50px", }}>
        <Container>

          <Typography variant="h4" sx={{ color: 'var(--primary) !important' }}>What is a clinical evaluation?</Typography>
          <br />

          <Typography component="span1">A clinical evaluation is a one-on-one session between a DHR approved substance abuse professional and yourself.   If you are charged with DUI, reckless driving, possession, or BUI, you may be required by a judge or probation officer. In many cases, your attorney may suggest that you complete an evaluation prior to your appearance in court. This is only to show that you have taken additional steps in good faith to please the court and further ask for leniency.</Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>

            <Grid item md={6}>
              <CardMedia
                className="hmeslidelft img-fluid"
                component="img"
                alt="circumstances"
                src={circumstances}
                sx={{ width: '100%', marginRight: 'auto', mb: '30px', }}
              />
              <Box sx={{ background: 'var(--primary)', padding: '16px', borderRadius: '10px' }}>
                <Typography sx={{ color: '#fff !important', }}><b>Sessions in both English & Spanish</b></Typography>
                <Typography sx={{ color: '#fff !important', }}><b>Available 7 days of the week</b></Typography>
                <Typography sx={{ color: '#fff !important', }}><b>Confidential sessions</b></Typography>
                <Typography sx={{ color: '#fff !important', }}><b>Sessions Offered Virtually as well</b></Typography>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Typography variant="h4" sx={{ color: 'var(--primary) !important' }}>Tailored to your unique Circumstances</Typography>

              <Grid sx={{ mt: '30px', display: 'flex', flexDirection: 'column', gap: '20px', }}>
                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">Clinical evaluations for DUIs charges and other drug charges (aka "substance abuse evaluation" or "drug and alcohol evaluation")</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">Anger Management Evaluations and Classes</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">Mental Health Evaluations</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">FVIP (Family Violence Intervention Program) Gender-specific classes</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">ASAM Level 1 Treatment classes</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">Theft/Shoplifting Classes</Typography>
                </Grid>

                <Grid sx={{ display: 'flex', }}>
                  <img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp;
                  <Typography component="span1">Drug Testing</Typography>
                </Grid>

              </Grid>

            </Grid>

          </Grid>

          <Grid container spacing={2} sx={{ mt: '50px', display: 'flex', gap: '19px', }} alignItems={'center'}>
            <Grid item md={6} sx={{ border: '1px solid #CCCCCC', borderRadius: '10px', padding: '16px !important', }}>
              {/* <Typography variant='h2'>Locations</Typography>
                <Grid sx={{mt:3, display:'flex', flexDirection:'column', gap:'20px', }}>

                  <Typography component='f14' sx={styles.locations1}>
                    <span style={{display:'flex', width:'30%'}}><PlaceIcon sx={{color:'var(--green)'}}/>
                    <span style={{color:'var(--primary)',display:'flex',}}>Doraville</span>
                    </span> 
                    <span style={{color:'#000 !important'}}>3042 Oak cliff Road, Suite 210, Doraville 30340</span>
                  </Typography>
                  <Typography component='f14' sx={styles.locations1}><span style={{display:'flex', width:'30%'}}><PlaceIcon sx={{color:'var(--green)'}}/><span style={{color:'var(--primary)',display:'flex',}}>Alpharetta</span></span> <span style={{color:'#000 !important'}}>11925 Jones Bridge Rd Suite 103, Alpharetta, GA 30005</span></Typography>
                  <Typography component='f14' sx={styles.locations1}><span style={{display:'flex', width:'30%'}}><PlaceIcon sx={{color:'var(--green)'}}/><span style={{color:'var(--primary)',display:'flex',}}>Sandy Springs</span></span> <span style={{color:'#000 !important'}}>4651 Roswell Rd., Bldg I-803, Atlanta, GA 30342</span></Typography>
                  <Typography component='f14' sx={styles.locations1}><span style={{display:'flex', width:'30%'}}><PlaceIcon sx={{color:'var(--green)'}}/><span style={{color:'var(--primary)',display:'flex',}}>Lawrenceville</span></span> <span style={{color:'#000 !important'}}>377 West Pike Street, Lawrenceville 30046</span></Typography>
                  <Typography component='f14' sx={styles.locations1}><span style={{display:'flex', width:'30%'}}><PlaceIcon sx={{color:'var(--green)'}}/><span style={{color:'var(--primary)',display:'flex',}}>Marietta</span></span> <span style={{color:'#000 !important'}}>2300 Shallowford Rd, Marietta, GA 30066</span></Typography>
                
                </Grid>                      */}

              {/* <Grid sx={{ border: '1px solid #CCCCCC', borderRadius: '10px', padding: '30px !important', mt: 3, }}> */}
              <Typography variant='h2' mb="14px" >IMPORTANT NOTE:</Typography>
              <Typography component='f14'>You don't have to go through this alone. We offer confidential evaluation for substance use, distinct from DUI programs which serves as a supportive environment to address your concerns.</Typography>
            </Grid>
            {/* </Grid> */}

            <Grid item md={5.8} sx={{ padding: '0px !important', }}>

              <Grid container sx={{ gap: '9px', }}>
                <Grid item md={12} sx={{ background: '#EFF7FF', padding: '30px 20px', display: 'flex', justifyContent: 'space-around', }}>

                  <Grid>
                    <Typography variant='h6' mb="10px" >EVALUATIONS</Typography>
                    <Typography component='f14'> • <b>Dr. Sia Parr: </b> 678-520-8010</Typography>
                  </Grid>

                  <Grid>
                    <Typography variant='h6' mb="10px" >ASAM 1 CLASSES</Typography>
                    <Typography component='f14'> • <b>Jackie Thomas: </b> 678-994-8046</Typography>
                  </Grid>

                </Grid>
              </Grid>


            </Grid>

          </Grid>

          <LocationCard />

        </Container>
      </Box>

    </>
  )
}

export default CePackages
