import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DlBanner from './DlBanner'
import Prominent from './Prominent'
import BehindTheWheel from './BehindTheWheel'
import DrivingLessonsFaq from './DrivingLessonsFaq'
import DlPackages from './DlPackages'
import { useLocation, useParams } from 'react-router-dom'
import OfferGreenBanner from './OfferGreenBanner'
import NewOffBanner from './newOffBanner'



const DrivingLesson = () => {

  const location = useLocation()
  const { number } = location.state || {}
  const [initialUpdate, setInitialUpdate] = useState(true)
  console.log(`😋😋 - `, location.state);
  useEffect(() => {



    if (initialUpdate) {
      if (number == 2) {
        window.scrollTo(0, 900);
      } else {
        window.scrollTo(0, 0);
      }
      setInitialUpdate(false)
    }
  }, [])

  return (
    <>
      <Header />
      <DlBanner />

      {/* <NewOffBanner /> */}

      <OfferGreenBanner />
      <DlPackages />
      <Prominent />
      <BehindTheWheel />
      <DrivingLessonsFaq />
      <Footer />
    </>
  )
}

export default DrivingLesson
