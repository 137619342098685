import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DdlFaqLft from "../../assets/images/DefensiveDrivingLocation/DdlFaq.png";

const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
}

const drivingLessonsFaq = [
  {
    question: 'What is a Defensive Driving Course (DDC)',
    answer: 'In Georgia, a Defensive Driving Course, also known as the Basic Driver Improvement Course (BDIC), is a program designed to improve driving skills and promote safe driving practices. It is often taken voluntarily by drivers to receive benefits like insurance discounts or point reduction.',
  },
  {
    question: 'Why should I consider taking a Defensive Driving Course?',
    answer: 'You should consider taking a Defensive Driving Course in Georgia to enhance your driving skills, reduce points on your driving record, qualify for insurance discounts, and fulfill court requirements for certain traffic violations.',
  },
  {
    question: "Is a Defensive Driving Course the same as a Traffic School or Driver Improvement Course?",
    answer: 'In Georgia, the terms "Defensive Driving Course," "Traffic School," and "Driver Improvement Course" are often used interchangeably to refer to programs that serve similar purposes.',
  },
  {
    question: "What topics are covered in a Defensive Driving Course?",
    answer: 'A Defensive Driving Course covers various topics, including defensive driving techniques, Georgia traffic laws, impaired driving, and sharing the road safely.',
  },







  {
    question: " Will completing a Defensive Driving Course remove points from my driving record?",
    answer: 'Yes, upon successful completion of a Defensive Driving Course, up to 7 points may be removed from your driving record. This can help maintain your driving privileges and reduce insurance costs.',
  },
  {
    question: " How much does a Defensive Driving Course typically cost, and are there any additional fees?",
    answer: 'The cost of a Defensive Driving Course in Georgia is set by GA Department of Driver Services and it’s $95.',
  },
  {
    question: "Can I take a Defensive Driving Course virtually, or is it only offered in a classroom setting?",
    answer: 'Georgia allows drivers to complete Defensive Driving Courses both in-person and virtually, providing flexibility and convenience. Certificate is same for both .',
  },
  {
    question: "Are Defensive Driving Course certificates recognized in all states, or do they vary by location?",
    answer: "Defensive Driving Course certificates earned in Georgia are typically recognized in other states. However, it's advisable to check with the specific state's Department of Motor Vehicles or equivalent agency for confirmation.",
  },
  {
    question: "Can I retake a Defensive Driving Course to refresh my skills or for additional benefits?",
    answer: 'In Georgia, you can retake a Defensive Driving Course every 3 years for insurance discounts, every 5 years for point reduction, and as needed for court related offenses.',
  },
  {
    question: " Can I dispute a traffic ticket or violation by taking a Defensive Driving Course?",
    answer: 'In some cases, completing a Defensive Driving Course may allow you to have a traffic ticket dismissed or reduce the severity of the violation. This is at the discretion of the court.',
  },

]


const DdrFaq = () => {
  return (
    <>
      <Box sx={{ py: "50px", position: "relative", }}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={DdlFaqLft} sx={styles.faqRightImage} />
              </div>

            </Grid>
            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default DdrFaq;
