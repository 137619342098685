
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { IoMdClose } from "react-icons/io";
import {
    Button,
    FormHelperText,
    Grid,
    Link,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack, Typography, DialogTitle, Dialog, DialogContent, IconButton, TextField, Divider, Chip
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { base_Url } from '../../pages/BaseUrl/ApiUrl';
import { Toaster, toast } from "sonner";
import ForgotPassword from './ForgotPassword';
import { IoWarningOutline } from "react-icons/io5";

const success = (data) => {
    toast.success(data, {
        position: 'top-center',
        style: { backgroundColor: 'green', color: 'white' },

        cancel: {
            label: "close",
            onClick: () => console.log('Cancel!'),
        },
    });
}

const failure = (data) => {
    toast.error(data, {
        position: 'top-center',
        style: { border: '1px solid red' },

        cancel: {
            label: "x",
            // style: {
            //     backgroundColor: '#a8261d', 
            //     color: '#ffffff', 
            //     border: 'none', 
            //     padding: '2px 8px',
            //     borderRadius: '4px', 
            // },
            onClick: () => console.log('Cancel!'),
        },

    });
}


const ExistingLogin = ({ setRegisterStatus, openStatus, data, finalData, classmode }) => {
    const navigate = useNavigate();



    const [passwordOpen, setPasswordOpen] = useState(false)

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const opensuccesstoast = (message) => {
        enqueueSnackbar(message, {
            variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: 2000
        })
    }
    const openfailuretoast = (message) => {
        enqueueSnackbar(message, {
            variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: 4000
        })
    }


    const [newUser, setNewUser] = useState(false)
    const [extUser, setExtUser] = useState(false)


    const handleNewUser = () => {
        setNewUser(true)

        setRegisterStatus(false)
    }


    console.log('finalData <<<<<#####3', finalData)


    const [Email, setEmail] = useState(null)
    const handleForgot = async (email) => {

        console.log('email', email)
        setEmail(email)

        try {
            const response = await axios.post(`${base_Url}/forgotPassword`, {
                email: email,
                roleid: 2

            })
            if (response.data.status) {

                success(response.data.message)
                setPasswordOpen(true)
                setRegisterStatus(false)
            }
            else {
                failure(response.data.message)
            }
        } catch (error) {
            console.log('error', error)
        }

    }

    return (
        <>
            <Toaster />
            <Dialog
                fullWidth
                maxWidth='sm'
                onClose={() => setRegisterStatus(false)}
                open={openStatus}
                sx={{ m: 2 }}>


                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5' sx={{ color: 'black !important' }}>
                            Welcome back !
                        </Typography>
                        <IconButton onClick={() => setRegisterStatus(false)} sx={{ color: 'red' }}>
                            <IoMdClose />
                        </IconButton>

                    </Stack>

                </DialogTitle>

                <DialogContent>


                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .matches(
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    'Invalid email format'
                                )
                                .required('Email is required'),
                            password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values,) => {
                            try {




                                // console.log('register form data', values);

                                const requestData = {
                                    configid: 1,
                                    email: values.email,
                                    password: values.password,
                                    roleid: '2',
                                    webfcmtoken: '23232323ss'
                                };

                                const response = await axios.post(
                                    `${base_Url}/login`,
                                    requestData, {
                                    withCredentials: true
                                }
                                );


                                console.log(response)


                                if (response.data.status === true) {
                                    opensuccesstoast(response.data.message)
                                    console.log('data', response.data)
                                    localStorage.setItem('Student', JSON.stringify(response.data.data));
                                    setRegisterStatus(false)
                                    navigate('/myprofile');
                                } else {
                                    openfailuretoast(response.data.message)
                                }

                            } catch (error) {
                                console.log('post register api status', error);
                            }



                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login"></InputLabel>
                                            <TextField
                                                size='small'
                                                label='Email'
                                                id="email-login"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Enter email address"
                                                fullWidth
                                                error={Boolean(touched.email && errors.email)}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ color: 'red !important' }}>
                                                    {errors.email}
                                                </FormHelperText>
                                            )}

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>

                                            <TextField
                                                sx={{ borderRadius: '30px !important' }}
                                                size='small'
                                                label='Password'
                                                fullWidth
                                                error={Boolean(touched.password && errors.password)}
                                                id="-password-login"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder="Enter password"
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="standard-weight-helper-text-password-login" sx={{ color: 'red !important' }}>
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>

                                    {errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Grid>
                                    )}
                                    {/* <Grid item xs={12} >

                                    </Grid> */}




                                    <Grid item xs={12} >

                                        <Stack>
                                            <Typography sx={{ mb: 2, cursor: 'pointer', float: 'right', alignItems: 'flex-end' }} onClick={() => handleForgot(values.email)}> Forgot password?</Typography>
                                            <Stack direction={'row'} spacing={0.5} sx={{ mb: 2, backgroundColor: '#FFCC00', padding: '7px' }}>
                                                {/* <IconButton sx={{fontSize:'40px',color:'red'}}>
                                                    <IoWarningOutline />
                                                </IconButton> */}
                                                <Typography  >
                                                    <b>New users:</b> Login details will be sent upon completing your course purchase.
                                                </Typography>
                                            </Stack>

                                        </Stack>

                                        <Button sx={{
                                            borderRadius: '30px',
                                            backgroundColor: 'black !important'
                                        }} disableElevation disabled={isSubmitting}
                                            fullWidth size="large" type="submit" variant="contained" >
                                            Login
                                        </Button>
                                    </Grid>



                                </Grid>
                            </form>
                        )}
                    </Formik>

                </DialogContent>
            </Dialog>


            <ForgotPassword
                passwordOpen={passwordOpen}
                setPasswordOpen={setPasswordOpen}
                Email={Email}

            />


        </>
    );
};

export default ExistingLogin;