import React, { useEffect, useState } from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography, Stack } from "@mui/material";

import PlaceIcon from '@mui/icons-material/Place';

import LWhoAre from "../../assets/videos/location map.mp4"
import { BASE_URL } from '../../config';
import axios from 'axios';

import imgh from '../../assets/newmap.png'


const locationList = [
    {
        locationNames: 'Sandy Springs',
    },
    {
        locationNames: 'Lawrenceville',
    },
    {
        locationNames: 'Sandy Springs',
    },
    {
        locationNames: 'Sandy Springs',
    },
]




const styles = {
    lWhoAreBut: {
        padding: '0px 10px 0px 4px',
        lineHeight: '0',
        justifyContent: 'left',
        background: 'var(--lightblue) ',
        textTransform: 'capitalize',
    },

    lWhoAreButIcon: {
        background: 'var(--primary)',
        padding: '5px',
        fontSize: '36px !important',
        color: '#fff',
        marginRight: '10px',
    }
}






const LwhoAre = () => {

    const [branchLocations, setBranchLocation] = useState([])

    const fetchLocations = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/getAllBranches`, {
                type: ''
            })
            if (response.data.status) {
                console.log('response bbbbbb', response?.data?.response)
                setBranchLocation(response?.data?.response)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        fetchLocations()

    }, [])



    const handleDirection = (latitude, longitude) => {

        console.log('latitude', latitude)

        if (latitude === "" && longitude === "") {
            alert("latitude & longitude is required")
        } else {
            const url = `https://www.google.com/maps/dir//${latitude},${longitude}`;
            window.open(url, "_blank");
        }

    };

    return (
        <>

            <Box sx={{ background: "", padding: "50px 0px" }}>
                <Container>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>

                        <Grid item xs={12} md={7} >

                            <Typography variant='h1' sx={{}}>We Are Spread Across Atlanta</Typography>
                            <Typography sx={{ pt: '20px' }}>Our locations are chosen in such a way that we can serve you better whether it’s  In-person or Remote.</Typography>

                            <Typography sx={{ pt: '30px', pb: '20px', }}>The areas include</Typography>




                            <Grid container alignItems="center" spacing={3} >
                                {branchLocations.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={6} alignItems='center' >

                                        {/* <a
                                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item?.address || item?.locationname)}`}
                                            target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> */}
                                        <Stack direction='row' spacing={1} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() =>
                                            handleDirection(
                                                item.latitude,
                                                item.longitude
                                            )
                                        }>

                                            <PlaceIcon sx={{ color: 'var(--green)', fontSize: '40px' }} />

                                            <Stack>
                                                <Typography sx={{ color: 'var(--primary) !important', fontSize: '16px !important', fontWeight: '600 !important' }}>
                                                    {item?.locationname}
                                                </Typography>
                                                <Typography sx={{ color: 'gray !important', fontSize: '13px !important' }}>
                                                    {item?.concatenatedAddress}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        {/* </a> */}

                                    </Grid>
                                ))}
                            </Grid>


                        </Grid>

                        <Grid item md={5}>
                            <CardMedia component="img" src={imgh} sx={{ width: '100%', margin: '20px auto' }} />
                            {/* <CardMedia component="video" autoPlay loop muted src={LWhoAre} /> */}
                        </Grid>

                    </Grid>
                </Container>
            </Box>



        </>
    )
}

export default LwhoAre
