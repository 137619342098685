import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import footban2 from "../../assets/images/home/footban2.jpg"
import faqlft from "../../assets/images/home/faq-lft.png";

import jlbanner from '../../assets/Z_jlaw.png'

const styles = {

    CancellationPolicyHeder: {
        background:`url(${footban2}) no-repeat top center`,backgroundSize:'100% 100%',padding:'160px 0px 60px',
        '@media (max-width: 900px)': {
          padding: '50px 0px 50px',
        }
    },

    cancellationHeading:{
        color:'var(--primary) !important',
    },
    faqRight: {

        '@media (max-width: 900px)': {
            margin:'auto',
    
        },
      },
      faqRightImage: {
        width:'80%',marginRight:'auto', marginY:'30px',
        '@media (max-width: 900px)': {
          width:'100%'
        }
      },

}

const JoshuaLawMain = () => {
  return (
    <>
      
      <Box>

            <Box sx={styles.CancellationPolicyHeder}>
                <Container>
                    <Typography variant='h4' sx={{color:'#fff !important',}}>Joshua's Law Information</Typography>
                </Container>
            </Box>

            <Box sx={{py:'60px'}}>

                <Container>
                    <Typography variant='h5' mb='50px'>What is Joshua’s Law?</Typography>
                
                    <Grid container spacing={2} sx={{display:'flex',alignItems:'center',}}>

                            <Grid item md={6}>
                                <Grid data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={faqlft} sx={styles.faqRightImage}/>
                                </Grid>
                            </Grid>

                            <Grid item md={6}>
                                <Grid>
                                    <Typography component='f22w500' sx={{display:'flex', alignItems:'baseline', p:'10px 0px 16px' }}>
                                        Are you familiar with Joshua's Law?
                                    </Typography>
                                    <Typography component='f16w600' >Joshua's Law Information for Parents of Teen Drivers in Georgia</Typography>

                                    <Typography sx={{textAlign:'justify', py:'10px'}}>In 2003, a young boy named Joshua Brown died in a car accident. He was driving in the rain, hit a large puddle of water, hydroplaned, and crashed straight into a tree. He died a seven days later. After this accident, his parents began a push for stronger driving safety laws to prevent other people from losing loved ones or their own lives in car accidents. The Browns recognized that Joshua had no idea what to do in this scenario, as he never had any driving law education when he started driving. Had he known how to maintain control of his car after hitting the puddle, he may have had a better chance at survival.</Typography>
                                    <Typography sx={{textAlign:'justify'}}>Four years later in 2005, the Browns managed to get Senate Bill 226 (also known as Joshua's Law) to impose new requirements on teenage drivers. These requirements were designed to ensure that EVERYONE maintains a good understanding of Georgia driving laws and keep the roads safer. Joshua's Law requires adequate information for teen drivers to make sure teens are prepared for any driving situation and ideally, protect themselves from harm.</Typography>
                                </Grid>
                            </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box sx={{py:'60px', background:'#e0efff'}}>
                <Container>

                        <Grid>
                            <Typography component='f16w600' >What is Joshua's Law?</Typography>

                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                                Senate Bill 226, also known as "Joshua's Law," was passed during the 2005 General Assembly. Beginning January 1, 2007, all 16 and 17 year olds applying for a Class D driver's license must have held permit for minimum 1 year and 1 day, complete an approved 30-hour driver's education course, complete 6 hours of behind the wheel driving lessons with a certified driver's training program, and complete a total of 40 hours of supervised driving, 6 hours of which must be at night, with a parent or guardian's sworn verification that these requirements have been met.
                            </Typography>

                            <Typography component='f16w600' >What if my child just moved in to the state of Georgia?</Typography>
                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                                Any 16 and 17 year old must have taken an approved 30-hour driver's education course and have completed 6 hours of behind the wheel driving lessons with a certified driver's training program to be issued a license in Georgia. Georgia DDS will make an exception if parents of child are active in the military.  Please contact them for more information.
                            </Typography>
                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                                If your teen has not completed an approved driver's education course or taken 6 hours of behind the wheel driving lessons with a certified driver's training program, they cannot get their driver's license until they reach age eighteen (18). Remember, forty (40) hours of driving experience, including six (6) hours of driving at night, is always required to receive any driver's license.
                            </Typography>
                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                            For more Joshua's Law information, visit the <a href="https://dds.georgia.gov/joshuas-law-requirements">Georgia DDS website.</a>
                            </Typography>
                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                            For more information on completing Joshua's Law requirements with 1 ACT Driving Schools, visit our <a href='https://www.1statlantaduischool.com/drivers-education/'>driver's education page.</a>
                            </Typography>
                            <Typography sx={{textAlign:'justify', py:'10px'}}>
                            We hope you choose 1 ACT Driving Schools as your source of Joshua's Law information and your school for completing the requirements!
                            </Typography>
                        </Grid>


                </Container>
                
            </Box>

      </Box>


    </>
  )
}

export default JoshuaLawMain
