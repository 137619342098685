import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CeFaqLft from "../../assets/images/ClinicalEvaluation/CeFaqLft.png";

const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
}

const drivingLessonsFaq = [
  {
    question: 'What is a DUI Risk Reduction Course?',
    answer: 'In Georgia, a DUI Risk Reduction Course is a program designed for individuals convicted of driving under the influence (DUI) or other drug or alcohol-related offenses. It aims to educate participants about the risks of impaired driving and substance abuse.',
  },
  {
    question: 'Who is required to take a DUI Risk Reduction Course?',
    answer: 'In Georgia, individuals convicted of DUI or other specified offenses are typically required by the court to complete a DUI Risk Reduction Course as a condition of license reinstatement.',
  },
  {
    question: "What are the goals and objectives of a DUI Risk Reduction Course?",
    answer: 'The course aims to help participants understand the consequences of impaired driving, make responsible choices, and reduce the risk of future DUI incidents. It covers the effects of alcohol and drugs on driving, Georgia laws, and coping with substance abuse.',
  },
  {
    question: "Can you provide information about the schedule of the DUI/Risk Reduction Course, including weekday and weekend classes and their fees?",
    answer: 'The course offers both weekday and weekend classes. Weekday classes are held on Tuesday, Wednesday, and Thursday, while weekend classes are available on Friday, Saturday, and Sunday. The total fee for the 20-hour DUI Risk Reduction class is $360, which includes a $100 fee for the Assessment Questionnaire and a $260 fee for the class and book.',
  },
  {
    question: "What is the NEEDS Assessment Questionnaire, and why is it required by the Georgia Department of Driver Services?",
    answer: "The NEEDS Assessment Questionnaire is a requirement by the Georgia Department of Driver Services (DDS) that must be completed prior to the start of your class. It's a brief 20-30 minute questionnaire, which can be conveniently filled out from the comfort of your home.",
  },
  {
    question: "Is NEEDS Assessment the same as a Drug and Alcohol Clinical Evaluation Assessment ?",
    answer: 'NO, These are two different assessments - NEEDS assessment is a Part of DUI Program and the other one comes under Clinical Evaluation.',
  },


  {
    question: "Is the Certificate of Completion the same whether the class is taken virtually via Zoom or in-person at the office?",
    answer: 'Yes, the Certificate of Completion is identical, regardless of whether you take the class virtually via Zoom or in-person at the office.',
  },

  {
    question: "Is NEEDS Assessment the same as a Drug and Alcohol Clinical Evaluation Assessment ?",
    answer: 'NO, These are two different assessments - NEEDS assessment is a Part of DUI Program and the other one comes under Clinical Evaluation.',
  },
  {
    question: "Can I take a DUI Risk Reduction Course in a different state if I received a DUI in a different state?",
    answer: 'Typically, you are required to take a DUI Risk Reduction Course in the state where the offense occurred, but most states may accept out-of-state completion.',
  },


]


const DrrzFaq = () => {
  return (
    <>

      <Box sx={{ py: "50px", position: "relative", }}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={CeFaqLft} sx={styles.faqRightImage} />
              </div>

            </Grid>
            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}

export default DrrzFaq

