import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import { IoCloseCircleOutline } from "react-icons/io5";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import footban2 from "../../assets/images/home/footban2.jpg"



const styles = {

    CancellationPolicyHeder: {
        background: `url(${footban2}) no-repeat top center`, backgroundSize: '100% 100%', padding: '160px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px',
        }
    },

    cancellationHeading: {
        color: 'var(--primary) !important',
    },

    CancellationPolicyCon: {
        display: 'flex', flexDirection: 'column', gap: '14px', mt: '40px',
    }


}





const TermsAndCondition = ({ open, setOpen }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'} justifyContent={'end'}>
                        <Tooltip title="Close">
                            <IconButton color='error' onClick={() => setOpen(false)}>
                                <IoCloseCircleOutline />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    <Box>


                        <Box sx={styles.CancellationPolicyHeder}>
                            <Container>
                                <Typography variant='h4' sx={{ color: '#fff !important', }}>CANCELLATION & REFUND POLICY</Typography>
                            </Container>
                        </Box>

                        <Box sx={{ padding: '40px 0px' }}>
                            <Container>
                                <Grid>

                                    <Typography variant='h2' marginBottom={2} sx={styles.cancellationHeading}>REFUND AND CANCELLATION/RESCHEDULING POLICY for DUI, DI, DT, & TPT</Typography>
                                    <Typography variant='p'> *All cancellation refunds are subject to a cancellation fee of 5%, minimum $5, unless noted differently, below. Our policy will apply to everyone no matter the nature of your emergency sickness/illness, personal schedule conflict, etc</Typography>



                                    <Grid sx={styles.CancellationPolicyCon}>


                                        <Typography variant='p'><b>DUI/RISK REDUCTION COURSE:</b></Typography>
                                        <Typography variant='p'> • If you have already started a class with us, you cannot get a refund for that class.</Typography>
                                        <Typography variant='p'> • If you must take a DUI/Risk Reduction Course and have taken your NEEDS assessment with us, paid in full, but wish to transfer to another school, as per DDS regulations, we are ONLY required to issue refunds if you are transferring to another school due to a course cancellation, program closure, or documented emergency, or because you have moved over 30 miles away from the location in which you have registered. We will refund you the course fee of $260 and transfer your NEEDS assessment.</Typography>
                                        <Typography variant='p'> • If you have not started your class yet but have completed your paperwork and need to reschedule to another date, then you will be charged a $25 rescheduling fee.</Typography>
                                        <Typography variant='p'> • If class has started and you need to reschedule, if you missed a session or were tardy, then you must pay the class fee ($260) again and retake the class from the very beginning.</Typography>
                                        <Typography variant='p'> • If the instructor kicks you out of class due to a class rule violation, then you must pay the class fee ($260) again and retake the class from the very beginning.</Typography>

                                        <br />

                                        <Typography variant='p'><b>DEFENSIVE DRIVING/DRIVER’S IMPROVEMENT AND 30-HR DRIVER’S EDUCATION:</b></Typography>
                                        <Typography variant='p'> • If WE cancel a Defensive Driving/Driver’s Ed., you are entitled to a refund if you would like to attend class at another school.</Typography>
                                        <Typography variant='p'> • If you have already started a class with us, you cannot get a refund for that class.</Typography>
                                        <Typography variant='p'> • If you have paid for a defensive driving/Driver’s Ed. class and don’t show up on the scheduled date, REFUNDS WILL NOT BE ISSUED.</Typography>
                                        <Typography variant='p'> • If you need to reschedule your scheduled class and paperwork has already been completed, then you must pay a $25 rescheduling fee to reschedule.</Typography>
                                        <Typography variant='p'> • If the instructor kicks you out of class due to a class rule violation, then you must pay a $25 fee and retake the class from the very beginning.</Typography>
                                        <br />

                                        <Typography variant='p'><b>DRIVING LESSONS:</b></Typography>
                                        <Typography variant='p'> • Refunds can be issued for driving lessons within 30 days of payment if you have not yet begun your driving lessons. If you have not started your lessons and would like to request a refund within that 30-day period, we will issue a refund and deduct a 20% administration fee from the payment amount. Refunds will NOT be issued after the 30-day period ends. Once you have started taking your driving lessons, NO refunds will be issued, even with 30 days of the payment.</Typography>
                                        <Typography variant='p'> • If a cancellation/rescheduling is made 48 hours or LESS in advance of scheduled lesson, the client will be charged a $60.00 cancellation/rescheduling fee and will not be able to reschedule their lesson until the fee is paid.</Typography>
                                        <Typography variant='p'> • If the client NO-SHOWS and/or stops the lesson for any reason, the client will NOT be able to reschedule the lesson. The client will lose that lesson completely. Client will NOT be refunded the money for that lesson. If additional hours are desired, the client must buy those hours.</Typography>
                                        <Typography variant='p'> • Our instructors will wait outside of the pickup location for 15 minutes. Over that period, they will call, text, knock on the door/ring the doorbell, or do anything else in their power to reach you. If they have not heard from you after 15 minutes, they are allowed to leave, and the lesson is counted as a no-show. That lesson cannot be rescheduled, and the money is nonrefundable once the lesson is classified as such.</Typography>
                                        <Typography variant='p'> • Driving lesson time cannot be used for road tests under any circumstance.</Typography>
                                        <Typography variant='p'> • <b>Transferring of Hours from One Student to Another:</b> We do NOT allow transferring of remaining hours for driving lessons unless the difference is paid between the credited amount to your account and the current rate showing on our website.</Typography>
                                        <br />
                                        <br />

                                        <Typography variant='p'><b>ON-SITE ROAD TEST (TPT):</b></Typography>
                                        <Typography variant='p'> • If a cancellation is made less than 12 days in advance of scheduled on-site road test, the client will be charged a $50.00 cancellation/rescheduling fee and will not be able to reschedule their test until the fee is paid.</Typography>
                                        <Typography variant='p'> • If a client arrives more than 15 minutes late OR were a NO-SHOW to their scheduled test appointment, the client will be charged a $50.00 late/no-show fee and client will need to reschedule for another test date. Client will not be able to reschedule their test until the fee is paid.</Typography>
                                        <Typography variant='p'> • If a client fails their 1st on-site road test, the client will be allowed a free re-take. We will need to still comply with DDS Rules and Regulations when rescheduling *If client fails their 2nd on-site test, they will be required to retest at a DDS Customer Service Center. *</Typography>

                                        <Typography variant='p'> • One-site Road tests are non-refundable once already scheduled. NO refunds will be made if the client cancels, no-shows, or fails the scheduled road test for any reason including unforeseen emergencies the client may have.</Typography>

                                        <Typography variant='p'> • Remaining driving lessons cannot be used towards road test fee.</Typography>

                                        <br />

                                    </Grid>

                                    <Typography variant='p'><b>1 ACT Driving Schools reserves the right to change our prices for driving lessons, driver’s education, and TPT at any time and does not guarantee that we will honor previous prices once prices are changed.</b></Typography>

                                </Grid>

                            </Container>
                        </Box>

                    </Box>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default TermsAndCondition;