import React, { useEffect } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CeBanner from './CeBanner';
import CeFaq from './CeFaq';
import CePackages from './CePackages';

const ClinicalEvaluation = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>
    <Header />
    <CeBanner />
    <CePackages />
    <CeFaq />
    <Footer />
    </>
  )
}

export default ClinicalEvaluation;
