import React, { useEffect, useState } from "react";

import { Box, Button, CardMedia, Container, Grid, Typography, Stack } from "@mui/material";
import { BrowserRouter as Link, useNavigate } from 'react-router-dom';

import { FaFacebookF } from "react-icons/fa";
import { BiLogoInstagram } from "react-icons/bi";
import { FaPinterestP } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';

import MailIcon from '@mui/icons-material/Mail';

import footlogo from "../../assets/images/home/foot-logo.png";
import footer_bck from "../../assets/images/home/footer_bck.png";
import footimg1 from "../../assets/images/home/footimg1.png";
import footimg2 from "../../assets/images/home/footimg2.png";
import footimg3 from "../../assets/images/home/footimg3.png";
import footimg4 from "../../assets/images/home/footimg4.png";
import footimg5 from "../../assets/images/home/footimg5.png";
import footimg6 from "../../assets/images/home/footimg6.png";

import PlaceIcon from '@mui/icons-material/Place';
import axios from "axios";
import { BASE_URL } from "../../config";


const locationCity = [

  {
    city: "vadavalli"
  }

]


const styles = {

  footerTop: {
    paddingY: "30px", margin: "0px", borderBottom: '1px solid #293750', display: 'flex', alignItems: 'center',
  },

  FooterSlideImg: {
    width: '100px', height: 'fit-content',
    '@media (max-width: 900px)': {
      maxWidth: '90px',
    }
  },

  FooterImageTop: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 0fr)',
    gap: '10px',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    }
  },
  copy: {
    fontSize: '12px !important', color: '#fff !important', fontWeight: '300 !important',
    '@media (max-width: 600px)': {
      textAlign: 'center',
    },
  },
  cursor: {
    cursor: 'pointer !important',
  },
  footerHeadings: {
    color: '#fff !important', fontWeight: '500 !important', fontSize: '18px !important', paddingBottom: '14px',
  },

  locationsCity: {
    color: '#fff !important',
    fontWeight: '300 !important',
    fontSize: '13px !important',
  }
}




const Footer = () => {

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {

    try {

      const locationResponse = await axios.post(`${BASE_URL}/getAllBranches`);


      if (locationResponse.data.status === true) {

        //const locationFooter = locationResponse.data.response

        setLocations(locationResponse.data.response);


      } else {

      }

    } catch (error) {

    }

  }

  useEffect(() => {

    getLocations()

  }, [])


  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          backgroundImage: `url(${footer_bck})`,
          backgroundColor: "var(--black)",
        }}
      >
        <Container maxWidth="lg">

          <Grid container spacing={2} className="footer-top" sx={styles.footerTop}>
            <Grid item xs={6} md={6}>
              <CardMedia
                className="botlftimg"
                component="img"
                alt="program Image"
                src={footlogo}
                sx={{ textAlign: "center", width: "150px", height: "100%", maxWidth: '100%', marginRight: '10px', }}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Grid sx={{ justifyContent: "right", display: "flex", alignItems: 'center' }}>
                <a href="https://www.facebook.com/1ActDrivingSchool" className="foot_icon" target="blank">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/_1actdrivingschools/" className="foot_icon" target="blank">
                  <BiLogoInstagram />
                </a>
                {/* <a href="" className="foot_icon">
                  <FaPinterestP />
                </a>
                <a href="" className="foot_icon">
                  <FaXTwitter />
                </a> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="footer-bot" sx={{ padding: "30px 0px 60px", margin: "0px", display: 'flex', gap: '20px', }}>

            <Grid item md={3}>
              <Typography variant="h6" sx={styles.footerHeadings}>Quick Links</Typography>

              <Grid spacing={2} display='flex' gap="30px">


                <Grid display='flex' direction='column' gap='12px'>
                  <Typography
                    component='a'
                    sx={styles.cursor}
                    onClick={() => {
                      navigate('/');
                      window.scrollTo(0, 0);
                    }}
                  >
                    Home
                  </Typography>
                  <Typography component='a' onClick={() => navigate('/our-locations')} sx={styles.cursor}>Location</Typography>
                  {/* <Typography component='a' sx={styles.cursor}>Cart</Typography> */}
                </Grid>

                <Grid display='flex' direction='column' gap='12px'>
                  <Typography component='a' onClick={() => navigate('/privacy-policy-cancellation-policy')}
                    sx={styles.cursor}>Cancellation & Refund Policy</Typography>
                  {/* <Typography component='a' sx={styles.cursor}>Refund Policy</Typography> */}

                </Grid>
              </Grid>
            </Grid>

            <Grid item md={3.2}>

              <Grid sx={styles.FooterImageTop}>

                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg1} sx={styles.FooterSlideImg} />
                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg2} sx={styles.FooterSlideImg} />
                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg3} sx={styles.FooterSlideImg} />
                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg4} sx={styles.FooterSlideImg} />
                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg5} sx={styles.FooterSlideImg} />
                <CardMedia className="hmeslidelft" component="img" alt="footer image" src={footimg6} sx={styles.FooterSlideImg} />

              </Grid>

            </Grid>

            <Grid item md={2.6}>
              <Typography variant="h6" sx={styles.footerHeadings}>Contact</Typography>
              <Grid display='flex' direction='column' gap='10px'>
                <a href="tel:770-274-4223"><CallIcon sx={{ color: 'var(--primary)', fontSize: '16px', }} /> &nbsp; 770-274-4223</a>
                <a href="sms:404-590-4570"><MessageIcon sx={{ color: 'var(--primary)', fontSize: '16px', }} /> &nbsp; 404-590-4570</a>
                {/* <a href="#" style={{display:'flex',}}><PlaceIcon sx={{color:'var(--primary)',fontSize:'16px',}}/>&nbsp; 2972 Westheimer Rd. Santa Ana, Illinois 85486 </a> */}
                <a href="mailto:info@1actdrivingschools.com"><MailIcon sx={{ color: 'var(--primary)', fontSize: '16px', }} />&nbsp; info@1actdrivingschools.com </a>
              </Grid>
            </Grid>




            <Grid item md={2.2}>
              <Typography variant="h6" sx={styles.footerHeadings}>Locations</Typography>
              <Grid display='grid' gap='2px 10px' gridTemplateColumns='repeat(2, 1fr)'>
                {locations?.map((item, i) => (
                  <Typography key={i} sx={styles.locationsCity}>{item.locationname}</Typography>
                ))}
              </Grid>
            </Grid>

          </Grid>

          <Typography sx={{ color: 'white !important', marginBottom: '10px' }} variant="h5">Our License</Typography>

          <Grid container spacing={1}>

            <Grid item sm={12} md={4} lg={3} >

              <Typography sx={{ color: 'white !important' }}>DUI License</Typography>
              <Typography sx={styles.locationsCity} >#10102 Sandy Springs</Typography>
              <Typography sx={styles.locationsCity}>#10424 Lawrenceville</Typography>
              <Typography sx={styles.locationsCity}>#10391 Alpharetta</Typography>
              <Typography sx={styles.locationsCity}>#10375 East Cobb/Marietta</Typography>
              <Typography sx={styles.locationsCity}>#10429 Johns Creek</Typography>
              <Typography sx={styles.locationsCity}>#10433 West Cobb/Marietta</Typography>

            </Grid>
            <Grid item sm={12} md={4} lg={3}>

              <Typography sx={{ color: 'white !important' }}>DI License</Typography>
              <Typography sx={styles.locationsCity} >#2148 Sandy Springs</Typography>
              <Typography sx={styles.locationsCity}>#2362 Lawrenceville</Typography>
              <Typography sx={styles.locationsCity}>#2315 East Cobb/Marietta</Typography>
              <Typography sx={styles.locationsCity}>#2352 Alpharetta</Typography>
              <Typography sx={styles.locationsCity}>#2368 Johns Creek</Typography>
              <Typography sx={styles.locationsCity}>#2372 West Cobb/Marietta</Typography>

            </Grid>
            <Grid item sm={12} md={4} lg={3}>

              <Typography sx={{ color: 'white !important' }}>DT License</Typography>
              <Typography sx={styles.locationsCity} >#664 Sandy Springs</Typography>
              <Typography sx={styles.locationsCity}>#673 Lawrenceville</Typography>
              <Typography sx={styles.locationsCity}>#697 Doraville</Typography>
              <Typography sx={styles.locationsCity}>#699 East Cobb/Marietta</Typography>
              <Typography sx={styles.locationsCity}>#730 Alpharetta</Typography>
              <Typography sx={styles.locationsCity}>#749 Johns Creek</Typography>
              <Typography sx={styles.locationsCity}>#755 West Cobb/Marietta</Typography>

            </Grid>
            <Grid item sm={12} md={4} lg={3}>

              <Typography sx={{ color: 'white !important' }}>TPT License</Typography>
              <Typography sx={styles.locationsCity}>#673 Lawrenceville</Typography>
              <Typography sx={styles.locationsCity}>#730 Alpharetta</Typography>

            </Grid>
          </Grid>




        </Container>
      </Box>

      <Box display="flex" justifyContent="center" backgroundColor="var(--primary)" paddingY="10px">
        <Typography sx={styles.copy}>© Copyright 2024 1 ACT Driving School All Rights Reserved.</Typography>
      </Box>
    </>
  );
};

export default Footer;
