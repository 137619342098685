import React, { useEffect } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DrrlBanner from './DrrlBanner';
import DrrlPackages from './DrrlPackages';
import DrrlFaq from './DrrlFaq';
import DrrlFeatures from './DrrlFeatures';
import DrrlGoogleRev from './DrrlGoogleRev';
import DrrlEasySteps from './DrrlEasySteps';
import HomeGoogleRev from '../Home/HomeGoogleRev';

const DuiRiskReductionLocation = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>

      <Header />
      <DrrlBanner />
      <DrrlPackages />
      <DrrlEasySteps />
      <DrrlFeatures />
      {/* <HomeGoogleRev /> */}
      {/* <DrrlGoogleRev /> */}
      <DrrlFaq />
      <Footer />

    </>
  )
};

export default DuiRiskReductionLocation
