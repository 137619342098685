import React, { useEffect } from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DrrzBanner from './DrrzBanner';
import DrrzPackages from './DrrzPackages';
import DrrzFeatures from './DrrzFeatures';
import DrrzEasySteps from './DrrzEasysteps';
import DrrzGoogleRev from './DrrzGoogleRev';
import DrrzFaq from './DrrzFaq';
import HomeGoogleRev from '../Home/HomeGoogleRev';
import { useLocation, useParams } from 'react-router-dom';


const DuiRiskReductionZoom = () => {

  // const { id } = useParams()

  const location = useLocation()

  const { number } = location.state || {}

  useEffect(() => {
    if (number == 1) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 500);
    }

  }, [])





  return (
    <>

      <Header />
      <DrrzBanner />
      <DrrzPackages />

      <DrrzEasySteps />
      <DrrzFeatures />
      {/* <HomeGoogleRev /> */}
      {/* <DrrzGoogleRev /> */}
      <DrrzFaq />
      <Footer />

    </>
  )
}

export default DuiRiskReductionZoom
