import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography, colors } from '@mui/material'

import safeToDriveBg from "../..//assets/images/driving-lesson/highway-bg.jpg"
import safeToDriveBanCen from "../../assets/images/driving-lesson/man-with-keys.png"
import sheal from "../../assets/images/driving-lesson/georgia-approved-seal2.png"

import DoneIcon from '@mui/icons-material/Done';

import Register from '../Register/Register'
import { useState } from 'react'


const styles = {

  dlBanner: {
    flexGrow: 1, background: `url(${safeToDriveBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  },
  orangeButton: {
    background: '#FC8800',
    color: '#fff !important',
    padding: '10px 40px',
    border: '1px solid #FC8800',
    mt: '30px',
    '&:hover': {
      border: '1px solid #FC8800'
    }
  },
  safeToDriveItems: {
    color: '#fff !important', alignItems: 'center', display: 'flex',
  }
}

const SafeToDrive = () => {

  const [registerStatus, setRegisterStatus] = useState(false)

  return (
    <>

      <Box sx={styles.dlBanner}>

        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>

            <Grid item md={4}>
              <Typography variant='h3' sx={{ color: '#FC8800 !important' }}>
                1ACT Driving Schools
              </Typography>
              <Typography variant='h2' sx={{ color: '#fff !important', pt: '20px' }}>In Partnership with Safe2Drive</Typography>
              <Typography sx={{ color: '#fff !important', pt: '20px' }}>Take this 30-hour online Driver Education course to get your Intermediate license and prepare for your full license. This course meets all Joshua's Law requirements.</Typography>
              <Typography sx={{ color: '#fff !important', pt: '20px' }}>All-Inclusive Course</Typography>
              {/* <Typography sx={{ color: '#fff !important', pt: '20px' }}>
                Approved by Georgia DDS
              </Typography>

              <Typography sx={{ color: '#fff !important', }}>
                Meets All Joshua's Law Requirements
              </Typography>

              <Typography sx={{ color: '#fff !important',  }}>
                Customer Support 7 Days A Week
              </Typography>

              <Typography sx={{ color: '#fff !important',  }}>
                Money-Back Guarantee*
              </Typography> */}

              {/* <Button sx={styles.orangeButton} href='https://www.safe2drive.com/student/en/registration/register_6.aspx'>Register</Button> */}

              <Button variant="outlined" sx={styles.orangeButton} component='a' href='https://www.safe2drive.com/one-act.aspx?Aff=1ActDriverEd' target='blank'>
               Register
              </Button>
              <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus} />

            </Grid>
            <Grid item md={4}>
              <CardMedia component="img" alt="Image" src={safeToDriveBanCen} />
            </Grid>
            <Grid item md={4}>
              <CardMedia component="img" alt="Image" src={sheal} sx={{ width: 'fit-content', margin: 'auto' }} />
              <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px', mt: '30px' }}>
                <Typography sx={styles.safeToDriveItems}> <DoneIcon />&nbsp;  Approved by Georgia DDS </Typography>
                <Typography sx={styles.safeToDriveItems}> <DoneIcon />&nbsp; Meets All Joshua's Law Requirements</Typography>
                <Typography sx={styles.safeToDriveItems}> <DoneIcon />&nbsp; Customer Support 7 Days A Week</Typography>
                <Typography sx={styles.safeToDriveItems}> <DoneIcon />&nbsp; Money-Back Guarantee*</Typography>
              </Grid>
            </Grid>

          </Grid>
        </Container>


      </Box>

    </>
  )
}

export default SafeToDrive
