import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';

import footban1 from "../../assets/images/home/footban1.jpg"
import footban2 from "../../assets/images/home/footban2.jpg"
import footban3 from "../../assets/images/home/footban3.jpg"
import botlftimg1 from "../../assets/images/home/botlftimg1.png"
import botlftimg2 from "../../assets/images/home/botlftimg2.png"
import botlftimg3 from "../../assets/images/home/botlftimg3.png"

const styles ={
  footerCarosuelItems: {
    margin:'0px',display:'flex',justifyContent:'space-between',alignItems:'center',
    '@media (max-width: 600px)': {
      gap:'20px',
    },
  }
}



const footercarosuel = [
  {
    caosuelbg: footban1 ,
    footbottomleftimg: botlftimg1 ,
    tittle:'Dominate every Mile with our Driving Lesson Courses',
    content: 'Conquer the roads with Confidence!',
  },
  {
    caosuelbg: footban2 ,
    footbottomleftimg: botlftimg2 ,
    tittle:"Don't let one mistake define you",
    content: 'Drive towards a fresh start with <br/> DUI / Risk Reduction',
  },
  {
    caosuelbg: footban3 ,
    footbottomleftimg: botlftimg3 ,
    tittle:'Stay in control & shield yourself from risks on the road',
    content: 'Be proactive, not reactive – master the <br/> art of Defensive Driving',
  },
]


const HomeBotCarosuel = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="slider-container footer_carosuel"
      style={{
        margin: "0px",
        overflow: "hidden",
        background:'linear-gradient(45deg, #0c1c2d, #093467)',
      }}
      >
      <Slider {...settings}>

        {footercarosuel.map((bottomCarosuel, index ) => (

        <Box key={index} sx={{background:`url(${bottomCarosuel.caosuelbg}) no-repeat top center`,backgroundSize:'100% 100%',padding:'40px 40px',}}>
          <Grid container spacing={2} item sx={styles.footerCarosuelItems}>
            <Grid className='foot_lft'>

              <CardMedia className="botlftimg" component="img" alt="footer Image" src={bottomCarosuel.footbottomleftimg}
              sx={{textAlign:'center', width:'260px', height:'100%',}}
              />

            </Grid>

            <Grid className='foot_cen' sx={{marginRight:'100px', '@media (max-width: 600px)': {marginRight:'0px'}}}>
              <Typography sx={{color:'#fff !important'}}> {bottomCarosuel.tittle}</Typography>
              <Typography variant='h4' sx={{color:'#fff !important'}} dangerouslySetInnerHTML={{ __html: bottomCarosuel.content.replace(/<br\s*\/?>/mg,"<br/>","'") }}></Typography>
            </Grid>

            <Grid className='foot_rht' sx={{ marginRight: '40px', }}>
                {/* <a href="?a=signup" className="btns btn_1 hvr-bounce-to-right">GET KNOW</a> */}
            </Grid>
            
          </Grid>
        </Box>
        ))}

      </Slider>

    </div>  

    </>
  )
}

export default HomeBotCarosuel
