import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";

import Location1img from "../../assets/images/Locations/Sandy Springs.png";
import Location2img from "../../assets/images/Locations/Lawrenceville.png";
import Location3img from "../../assets/images/Locations/Marietta.png";

import PlaceIcon from "@mui/icons-material/Place";
import DirectionsIcon from "@mui/icons-material/Directions";
import axios from "axios";
import { BASE_URL } from "../../config";

const styles = {
  DirectionsButton: {
    border: "1px solid #000",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    background: "#fff",
    lineHeight: "2 !important",
    padding: "4px 10px !important",
    display: "flex",
    gap: "10px",
    "&:hover": {
      background: "var(--primary) !important",
    },
    cursor: 'pointer'
  },
  scroolbarLocation: {
    height: "664px",
    overflow: "hidden scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 900px)": {
      height: "890px",
    },
  },
  locationTop: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
};

const ourLocationContent = [
  {
    locationImage: Location1img,
    locationNAme: "Sandy Springs",
    locationLink:
      "https://www.google.com/maps/dir//1ACT+Driving+Schools+377+W+Pike+St+Lawrenceville,+GA+30046/@33.9581274,-83.9986183,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5be8b0a2695ef:0xff868d41471346cc!2m2!1d-83.9986183!2d33.9581274?entry=ttu",
    locationNav:
      "4651 Roswell Rd Bldg. I-803, Sandy Springs, GA 30342, United States",
  },
  {
    locationImage: Location2img,
    locationNAme: "Lawrenceville",
    locationLink:
      "https://www.google.com/maps/dir//1ACT+Driving+Schools+377+W+Pike+St+Lawrenceville,+GA+30046/@33.9581274,-83.9986183,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5be8b0a2695ef:0xff868d41471346cc!2m2!1d-83.9986183!2d33.9581274?entry=ttu",
    locationNav:
      "4651 Roswell Rd Bldg. I-803, Sandy Springs, GA 30342, United States",
  },
  {
    locationImage: Location3img,
    locationNAme: "Marietta",
    locationLink:
      "https://www.google.com/maps/dir//1ACT+Driving+Schools+377+W+Pike+St+Lawrenceville,+GA+30046/@33.9581274,-83.9986183,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5be8b0a2695ef:0xff868d41471346cc!2m2!1d-83.9986183!2d33.9581274?entry=ttu",
    locationNav:
      "4651 Roswell Rd Bldg. I-803, Sandy Springs, GA 30342, United States",
  },
  {
    locationImage: Location3img,
    locationNAme: "Doraville",
    locationLink:
      "https://www.google.com/maps/dir//1ACT+Driving+Schools+377+W+Pike+St+Lawrenceville,+GA+30046/@33.9581274,-83.9986183,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5be8b0a2695ef:0xff868d41471346cc!2m2!1d-83.9986183!2d33.9581274?entry=ttu",
    locationNav:
      "4651 Roswell Rd Bldg. I-803, Sandy Springs, GA 30342, United States",
  },
  {
    locationImage: Location3img,
    locationNAme: "Lawrenceville",
    locationLink:
      "https://www.google.com/maps/dir//1ACT+Driving+Schools+377+W+Pike+St+Lawrenceville,+GA+30046/@33.9581274,-83.9986183,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5be8b0a2695ef:0xff868d41471346cc!2m2!1d-83.9986183!2d33.9581274?entry=ttu",
    locationNav:
      "4651 Roswell Rd Bldg. I-803, Sandy Springs, GA 30342, United States",
  },
];

const LOurLocations = () => {
  const [getBranches, setGetBranches] = useState([]);

  console.log("getBranches1", getBranches);

  const getAllBranches = async () => {
    try {
      const gowthBranch = await axios.post(`${BASE_URL}/getAllBranches`);

      if (gowthBranch.data.status === true) {
        setGetBranches(gowthBranch.data.response);
      } else {
        console.log("gowth", gowthBranch);
      }
    } catch (error) {
      console.log("Get All Branches", error);
    }
  };

  useEffect(() => {
    getAllBranches();
  }, []);

  const handleDirection = (latitude, longitude) => {

    console.log('latitude', latitude)

    if (latitude === "" && longitude === "") {
      alert("latitude & longitude is required")
    } else {
      const url = `https://www.google.com/maps/dir//${latitude},${longitude}`;
      window.open(url, "_blank");
    }

  };

  const handleLocationTab = () => {

  }

  return (
    <>
      <Box sx={{ py: "60px" }}>
        <Container>
          <Typography variant="h1" sx={{ pb: 4 }}>
            Our Locations
          </Typography>

          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid md={6} item sx={styles.scroolbarLocation}>
              {getBranches.map((OurLocationItem, index) => (
                <Box
                  item
                  key={index}
                  className="ourLocation"
                  sx={{ marginBottom: "20px" }}
                  onClick={() => handleLocationTab()}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      padding: "10px",
                      "@media (max-width: 900px)": { flexDirection: "column" },

                    }}
                  >
                    <CardMedia
                      item
                      component="img"
                      sx={{ width: "100%", maxWidth: "120px", height: '120px', objectFit: 'fill' }}
                      src={OurLocationItem.image}
                      alt="Locations"
                    />
                    <Grid item sx={{ width: "100%" }}>
                      <Grid sx={styles.locationTop}>
                        <Typography component="span1" sx={{ display: "flex" }}>
                          {" "}
                          <PlaceIcon sx={{ color: "var(--green)" }} />
                          {OurLocationItem.locationname}
                        </Typography>
                        <Link
                          sx={styles.DirectionsButton} // Make sure `styles.DirectionsButton` is defined
                          onClick={() =>
                            handleDirection(
                              OurLocationItem.latitude,
                              OurLocationItem.longitude
                            )
                          }
                        >
                          <DirectionsIcon /> View Direction
                        </Link>
                      </Grid>
                      {/* <Typography
                        sx={{
                          pt: "40px",
                          pb: "16px",
                          "@media (max-width: 900px)": { pb: "8px" },
                        }}
                      >
                        1ACT Driving Schools
                      </Typography> */}
                      <Typography component="f14" >
                        {OurLocationItem?.concatenatedAddress}
                      </Typography>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Grid>

            <Grid item md={6}>
              <Box >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.29933576635!2d-84.380371288453!3d33.88194387310986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f184ac0fda1%3A0x4a53cd7f90016a25!2s4651%20Roswell%20Rd%20Bldg.%20I-803%2C%20Sandy%20Springs%2C%20GA%2030342%2C%20USA!5e0!3m2!1sen!2sin!4v1713760034075!5m2!1sen!2sin"
                  width="100%"
                  height="664px"
                  style={{
                    border: 1,
                    minHeight: "100%",
                    width: "1000px",
                    maxWidth: "100%",
                  }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Grid>

          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default LOurLocations;
