import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const routeMeta = {
    '/': {
        title: "Defensive Driving School and Drivers Ed in Georgia",
        description: "Enroll today for driving school lessons in Georgia with locations in Sandy Springs, Johns Creek, Lawrenceville, Doraville, Marietta, and Alpharetta."
    },
    '/schedule-driving-lessons-2': {
        title: "Schedule Driving Lessons Atlanta with 1 ACT Driving Schools",
        description: "Schedule driving lessons Atlanta with 1 ACT Driving Schools and you'll be paying the LOWEST prices in the state for the BEST service!"
    },
    '/drivers-education': {
        title: "Driver's Education Program at 1 ACT Driving Schools",
        description: "1 ACT Driving Schools offers driver's education packages at the LOWEST prices in Georgia to help your teen complete their Joshua's Law requirements."
    },
    '/online-drivers-education': {
        title: "30-Hour Online Driver's Education Course with 1 ACT Driving Schools",
        description: "1 ACT Driving Schools has partnered with Safe2Drive to provide an online driver's education course meeting Joshua’s Law requirements."
    },
    '/gdec-scholarship': {
        title: "Defensive Driving School and Drivers Ed in Georgia",
        description: "Enroll today for driving school lessons in Georgia with locations in Sandy Springs, Johns Creek, Lawrenceville, Doraville, Marietta, and Alpharetta."
    },
    '/road-test-services': {
        title: "Road Test Services in Georgia | 1 Act Defensive Driving Schools",
        description: "1 ACT Driving Schools road test services let you use our car for your test AND give you an hour of practice right before the test!"
    },
    '/defensive-driving-course-remote': {
        title: "Defensive Driving Course Remote",
        description: "The best Defensive Driving course Remote in Atlanta DDS-certified offered by 1 ACT Driving Schools"
    },
    '/defensive-driving-at-location': {
        title: "Defensive Driving at Location (In Person) - 1 ACT Driving Schools",
        description: "The best Defensive Driving course at Location in Atlanta DDS-certified offered by 1 ACT Driving Schools"
    },
    '/dui-risk-reduction-course-remote': {
        title: "DUI/Risk Reduction Course Remote in Atlanta",
        description: "1 ACT Driving Schools DDS-certified DUI/Risk Reduction course remote offers weekday & weekend classes."
    },
    '/dui-risk-reduction-at-location': {
        title: "DUI/Risk Reduction Course Remote in Atlanta",
        description: "DUI Risk Reduction At Location - 1 ACT Driving Schools weekday & weekend classes available"
    },
    '/clinical-evaluation': {
        title: "DUI Clinical Evaluation - Defensive Driving School in Georgia",
        description: "Enroll today for DUI evaluation in Georgia with locations in Sandy Springs, Johns Creek, Lawrenceville, Doraville, Marietta, and Alpharetta."
    },
    '/victim-impact-panel': {
        title: "VICTIM IMPACT PANEL - 1 ACT Driving Schools",
        description: "Victim impact Panel to help driving offenders"
    },
    '/our-locations': {
        title: "Our Locations - 1 ACT Driving Schools",
        description: "Driving lessons in Georgia with locations in Sandy Springs, Johns Creek, Lawrenceville, Doraville, Marietta, and Alpharetta."
    }
};

const DynamicMeta = () => {
    const location = useLocation();
    const meta = routeMeta[location.pathname] || {
        title: "1 ACT Driving Schools Drive Safe. Drive Smart. Drive with Us!",
        description: "At 1Act Driving School, we are dedicated to providing top-notch driver education tailored to all experience levels. Our mission is to empower students with the knowledge and skills necessary to become safe and confident drivers. We offer a comprehensive range of programs, including Behind-the-Wheel Training, DUI/Risk Reduction, Defensive Driving, Drivers Education (Joshua's Law), and Road Testing, all available across seven convenient locations in Atlanta (Sandy Springs, Doraville, Johns Creek, Marietta-EC, Lawrenceville, Alpharetta, Marietta-WC)",
    };


    const DESCRIPTION = meta.description || ''
    console.log(location.pathname, meta);
    return (
        <Helmet
            onChangeClientState={() => {
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', DESCRIPTION);
                }
            }}
        >
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
        </Helmet>
    );
};

export default DynamicMeta;
