import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import Register from '../Register/Register'
import { useState } from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import conex from "../../assets/images/driving-lesson/conex.png"

import drivingLessonJoshuasLaw from "../../assets/videos/DLjoshuas-law.png"
import axios from 'axios'
import { base_Url } from '../BaseUrl/ApiUrl'
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics'
import NewUserRegister from '../Register/NewUserRegister'

const styles = {
  bannerRight: {
    '@media (max-width: 900px)': {
      paddingTop: '30px',
    }
  },
  dlBanner: {
    flexGrow: 1, background: `url(${drivingLesson}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  }
}


const DljlBanner = () => {


  const [roadTesting, setRoadTesting] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)

        const course5 = response?.data?.response[3]
        setRoadTesting(course5)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])
  console.log('roadTesting', roadTesting)



  const [registerStatus, setRegisterStatus] = useState(false)

  const [finalData, setFinalData] = useState({})

  const [newUser, setNewUser] = useState(false)


  const fetchcheckPackageisExist = async (packageid) => {

    const isLoggedIn = localStorage.getItem('Student')
    
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: packageid
      })
      if (response.data.message === 'success') {

        const courseData = response?.data?.response?.packages?.map((val) => {
          return {
            productName: val?.packagename,
            productPrice: val?.packageamount
          }
        })

        googleAnalyticsFunction(response?.data?.response, courseData)
        setFinalData(response?.data?.response)

        // if (isLoggedIn == null) {
          setNewUser(true)
        // }
        // else {
        //   setRegisterStatus(true)
        // }


      } else {
        setFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }



  console.log('finalData', finalData)

  return (
    <>
      <Box sx={styles.dlBanner}>
        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={7}>
              <Typography
                variant='h5'
                component=""
                sx={{
                  fontWeight: "400 !important",
                  lineHeight: "1.5",
                  color: "#fff !important",
                }}
              >
                DDS-Approved
              </Typography>

              <Typography
                variant="h1"
                sx={{ color: "var(--yellow) !important", mt: '20px', }}
              >
                Drivers Education Joshua’s Law
              </Typography>

              <Grid sx={{ mt: "30px", display: 'flex', flexDirection: 'column', gap: '10px', }}>

                {/* <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Creating safe and responsible drivers
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Our experienced driver's education experts guide you through Joshua Law.
                  </Typography>
                </Grid> */}

                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Courses recognized by every County across Georgia
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    30-hour Drivers Education course offered LIVE via Zoom
                  </Typography>
                </Grid>

              </Grid>

              <Button variant="outlined" className='greenButAni' onClick={() => fetchcheckPackageisExist([roadTesting?.courseDetails[0]?.packageid])}>
                REGISTER NOW
              </Button>


              <NewUserRegister
                openStatus={newUser}
                setRegisterStatus={setNewUser}
                finalData={finalData}
              />

              {/* <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
                classmode={'1'}
              /> */}


            </Grid>

            <Grid item md={5}>
              <CardMedia className="" component="img" alt="location Banner image" src={drivingLessonJoshuasLaw} sx={styles.bannerRight} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DljlBanner;
