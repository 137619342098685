import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import GdecInfo from './GdecInfo'



const GDECScholarship = () => {
  return (
    <>
         
    <Header />
    <GdecInfo />
    <Footer />
      
    </>
  )
}

export default GDECScholarship
