

/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Stack, Button, Grid, TextField, InputAdornment, InputLabel, Typography, Avatar, CircularProgress, Autocomplete, Checkbox, Box } from '@mui/material';
import { CheckOutlined } from '@ant-design/icons';
import cvv from '../../assets/cvv.png';
import master from '../../assets/master-card.png';
import MainCard from '../../components/MainCard';
import visa from '../../assets/checkout/purepng.com-visa-logologobrand-logoiconslogos-251519938794uqvcz.png'
import visa2 from '../../assets/checkout/American-Express-Color.png'
import visa3 from '../../assets/checkout/Apple_Pay-Logo.wine.png'
import visa4 from '../../assets/checkout/Discover-logo.png'
import visa5 from '../../assets/checkout/Mastercard-Logo-2016-2020.png'
import visa6 from '../../assets/checkout/jcb_card_payment-512.webp'
import visa7 from '../../assets/checkout/paypal-icon-2048x1286-ha8kzb8k.png'
import visa8 from '../../assets/checkout/2166341.webp'
import axios from 'axios';
import TermsAndCondition from '../Register/TermsAndCondition';
import { toast, Toaster } from 'sonner';
import { googleTagForExistingUserCardDetails } from './../googleanalytics/googleanalytics';




const ExistingPaymentPage = ({ onClickSubmit, setActiveStep, coursedetails, paymentDetails, setPaymentDetails, formData, setFormData, selected, setSelected }) => {

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }


    const [errors, setErrors] = useState({});

    const validateCardNumber = (number) => {
        const regex = /^\d{15,16}$/;
        return regex.test(number);
    };

    const validateExpiryDate = (month, year) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100;

        if (!month || !year) return false;
        if (month < 1 || month > 12) return false;
        if (year < currentYear || (year === currentYear && month < currentMonth)) return false;
        return true;
    };

    const validateCVV = (cvv) => {
        const regex = /^\d{3,4}$/; // CVV should be 3 or 4 digits
        return regex.test(cvv);
    };



    const handleSubmit = () => {


        // 🔍 google tag manager 🔍
        if (paymentDetails) {
            googleTagForExistingUserCardDetails(paymentDetails)
        }
        // 🔍 google tag manager 🔍



        const { cardNumber, expiryMonth, expiryYear, cvvNumber } = paymentDetails;
        const newErrors = {};

        // Validate each field and collect errors
        if (!validateCardNumber(cardNumber)) {
            newErrors.cardNumber = 'Invalid card number. It must be 16 digits.';
            failure('Invalid card number.Please check and try again.');
            return;
        }
        if (!validateExpiryDate(expiryMonth, expiryYear)) {
            newErrors.expiryDate = 'Invalid expiry date. Please check and try again.';
            failure('Invalid expiry date. Please check and try again.');
            return;
        }
        if (!validateCVV(cvvNumber)) {
            newErrors.cvvNumber = 'Invalid CVV. It must be 3 or 4 digits.';
            failure('Invalid CVV.Please check and try again.');
            return;
        }

        setErrors(newErrors);

        if (check == false) {
            return failure('please check terms and conditions to proceed')
        }

        // if (Object.keys(newErrors).length === 0) {
        onClickSubmit();
        // }
    };





    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation logic
        if (name === 'cvvNumber') {
            if (value.length > 4) {
                return;
            }
        }

        if (name === 'expiryMonth') {
            if (value.length > 2) {
                return;
            }
        }

        if (name === 'expiryYear') {
            if (value.length > 2) {
                return;
            }
        }

        // if (name === 'cardNumber') {
        //     if (!/^\d*$/.test(value)) {
        //         console.error('Card number should contain only digits');
        //         return;
        //     }
        // }

        setPaymentDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handle = () => {
        if (roadTest) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    };

    const getImage = () => {
        return <img src={master} alt="card" />;
    };



    console.log('paymentDetails', paymentDetails)



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setPaymentDetails({
            ...paymentDetails,
            [name]: value,
        });
    };

    const [check, setCheck] = useState(false)


    // terms and condition in popup
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // terms and condition in popup


    // city , state , country
    const [countryInfo, setCountryInfo] = useState([]);
    const [countryLoading, setCountryLoading] = useState(true)
    const fetchCountryInfo = async () => {
        try {
            const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/iso`);
            const data = response?.data?.data;
            const result = data.map((item) => ({
                id: item.Iso2,
                label: item.name
            }));

            setCountryInfo(result);
            setCountryLoading(false)
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };


    const [stateInfo, setStateInfo] = useState([]);
    const [stateLoading, setStateLoading] = useState(false)
    const fetchState = async (id) => {

        try {
            setStateInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
                "country": id
            }
            );
            const data = response?.data?.data?.states;
            const result = data.map((item) => ({
                id: item.state_code,
                label: item.name
            }));
            setStateLoading(false)
            setStateInfo(result);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };



    const [cityInfo, setCityInfo] = useState([]);
    const [cityLoading, setCityLoading] = useState(false)

    const fetchCity = async (selectedStateId, country) => {

        try {
            setCityInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
                "country": `${country}`,
                "state": `${selectedStateId}`
            });
            const data = response.data.data;
            const result = data?.map((item) => ({
                id: item,
                label: item
            }));
            setCityInfo(result);
            setCityLoading(false)
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchCountryInfo()
        setPaymentDetails({
            ...paymentDetails,
            ['newstate']: 'Georgia',
        });
    }, []);


    useEffect(() => {


        if (paymentDetails?.billingcountryz) {
            fetchState(paymentDetails?.billingcountryz)
        }

        if (paymentDetails?.billingcountryz && paymentDetails?.billingstatez) {
            fetchCity(paymentDetails?.billingstatez, paymentDetails?.billingcountryz)
        }
    }, [paymentDetails?.billingcountryz]);

    // city , state , country

    return (
        <div>
            <Toaster />

            {/* <Box>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Card Number *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                name="cardNumber"
                                value={paymentDetails?.cardNumber}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (/^\d{0,16}$/.test(value)) {
                                        setPaymentDetails((prev) => ({
                                            ...prev,
                                            cardNumber: value,
                                        }));
                                    }
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>CardHolder Name *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                value={paymentDetails?.cardholderfirstname}
                                onChange={(e) =>
                                    setPaymentDetails((prev) => ({
                                        ...prev,
                                        cardholderfirstname: e?.target?.value,
                                    }))
                                }
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Stack spacing={1}>
                                    <InputLabel>Expiry Date *</InputLabel>
                                    <Grid container>
                                        <Grid item xs={6} sx={{ marginRight: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="MM"
                                                value={paymentDetails?.expiryMonth}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryMonth: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="YY"
                                                value={paymentDetails?.expiryYear}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryYear: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1}>
                                    <InputLabel>CVV *</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="password"
                                        value={paymentDetails?.cvvNumber}
                                        onChange={(e) => {
                                            const value = e?.target?.value;
                                            if (/^\d{0,4}$/.test(value)) {
                                                setPaymentDetails((prev) => ({
                                                    ...prev,
                                                    cvvNumber: value,
                                                }));
                                            }
                                        }}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Billing ZIP Code *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="cardzipcode"
                                value={paymentDetails?.cardzipcode}
                                onChange={handleChange2}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>Accepted Cards:</Typography>
                            <img src={visa} alt="Visa" width={50} />
                            <img src={visa2} alt="Visa 2" width={60} />
                            <img src={visa4} alt="Visa 4" width={60} />
                            <img src={visa5} alt="Visa 5" width={50} />
                        </Stack>
                    </Grid>

                </Grid>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox checked={check} onClick={() => setCheck(!check)} />
                    <Typography>
                        I have read and agree to the Cancellation Policy in{' '}
                        <Typography
                            component="span"
                            sx={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(true)}
                        >
                            terms and conditions
                        </Typography>
                    </Typography>
                </Stack>
            </Box> */}

            <Box>
                <Grid container spacing={2}>
                    {/* Card Number Section */}
                    <Grid item xs={12} md={12}>
                        <Stack spacing={1}>
                            <InputLabel>Card Number *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                name="cardNumber"
                                value={paymentDetails?.cardNumber}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (/^\d{0,16}$/.test(value)) {
                                        setPaymentDetails((prev) => ({
                                            ...prev,
                                            cardNumber: value,
                                        }));
                                    }
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Stack>
                    </Grid>

                    {/* Expiry and CVV Section */}
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Expiry Date *</InputLabel>
                                    <Grid container >
                                        <Grid item xs={5} sx={{ marginRight: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="MM"
                                                value={paymentDetails?.expiryMonth}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryMonth: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                placeholder="YY"
                                                value={paymentDetails?.expiryYear}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryYear: value,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>CVV *</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="password"
                                        value={paymentDetails?.cvvNumber}
                                        onChange={(e) => {
                                            const value = e?.target?.value;
                                            if (/^\d{0,4}$/.test(value)) {
                                                setPaymentDetails((prev) => ({
                                                    ...prev,
                                                    cvvNumber: value,
                                                }));
                                            }
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Cardholder Name and Zip Code Section */}
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Name as on Card *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                value={paymentDetails?.cardholderfirstname}
                                onChange={(e) =>
                                    setPaymentDetails((prev) => ({
                                        ...prev,
                                        cardholderfirstname: e?.target?.value,
                                    }))
                                }
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel>Billing ZIP Code *</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="cardzipcode"
                                value={paymentDetails?.cardzipcode}
                                onChange={handleChange2}
                            />
                        </Stack>
                    </Grid>

                    {/* Accepted Cards */}
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>Accepted Cards:</Typography>
                            <img src={visa} alt="Visa" width={50} />
                            <img src={visa2} alt="Visa 2" width={60} />
                            <img src={visa4} alt="Visa 4" width={60} />
                            <img src={visa5} alt="Visa 5" width={50} />
                        </Stack>
                    </Grid>
                </Grid>

                {/* Terms and Conditions */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox checked={check} onClick={() => setCheck(!check)} />
                    <Typography>
                        I Have read and agree to the Cancellation Policy in{' '}
                        <Typography
                            component="span"
                            sx={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(true)}
                        >
                            terms and conditions
                        </Typography>
                    </Typography>
                </Stack>

                {/* Modal for Terms and Conditions */}
                <TermsAndCondition open={open} setOpen={setOpen} />
            </Box>


            {/* 
            <MainCard>


                <Grid container spacing={2} sx={{ p: 2, }}>

                    <Grid item xs={12} md={12}>
                        <Stack>

                            <TextField
                                required
                                fullWidth
                                size="medium"
                                type="text"
                                name="billingaddressz"
                                value={selected ? formData?.billingaddress : paymentDetails?.billingaddressz}
                                onChange={selected ? handleChange : handleChange2}
                                // label=' Address'
                                placeholder=' Address'
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            id="cityInfo"
                            options={cityInfo ? cityInfo : []}
                            loading={cityLoading}
                            getOptionLabel={(option) => option.label}
                            value={cityInfo?.find((option) => option?.label == paymentDetails?.billingcityz) || null}
                            onChange={(event, value) => {

                                if (value) {
                                    setPaymentDetails({
                                        ...paymentDetails,
                                        ['billingcityz']: value?.label,
                                    });
                                } else {
                                    setPaymentDetails((prevFormData) => ({
                                        ...prevFormData,
                                        billingcityz: '',
                                    }));
                                }

                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="City name" label='City' size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {cityLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}

                                />
                            )}
                            autoHighlight={true}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="state-input"
                            loading={stateLoading}
                            options={stateInfo || []}
                            value={stateInfo?.find((option) => option?.label == paymentDetails?.billingstatez) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setCityLoading(true)
                                    setPaymentDetails((prevFormData) => ({
                                        ...prevFormData,
                                        billingstatez: value?.label,
                                        billingcityz: '', // Clear the city when state changes
                                    }));
                                    fetchCity(value?.label, paymentDetails?.billingcountryz);
                                } else {
                                    setPaymentDetails((prevFormData) => ({
                                        ...prevFormData,
                                        billingstatez: '',
                                        billingcityz: '', // Clear the city when state is cleared
                                    }));
                                }

                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="State" size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {stateLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="country-input"
                            loading={countryLoading}
                            options={countryInfo || []}
                            value={countryInfo?.find((option) => option?.label === paymentDetails?.billingcountryz) || null}
                            onChange={(event, value) => {

                                if (value) {
                                    setStateLoading(true)
                                    // setCityLoading(true)
                                    setPaymentDetails((prevFormData) => ({
                                        ...prevFormData,
                                        billingcountryz: value?.label,
                                        billingcityz: '',
                                        billingstatez: '',
                                    }));
                                    fetchState(value?.label);
                                } else {
                                    // setStateLoading(true)
                                    setPaymentDetails((prevFormData) => ({
                                        ...prevFormData,
                                        billingcountryz: '',
                                        billingstatez: '',
                                        billingcityz: '',
                                    }));
                                }

                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" size="small"

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="billingzipcode"
                                value={paymentDetails?.billingzipcode}
                                onChange={handleChange2}
                                // label='Zip code'
                                placeholder='Zip code'
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Grid>

                </Grid>



            </MainCard> */}





            <Stack justifyContent="space-between" direction="row" sx={{ mt: 3 }}>
                {
                    roadTest ?
                        <Button variant="contained" onClick={() => setActiveStep(pre => pre - 1)}>Back</Button>

                        :
                        <Button sx={{ display: 'none' }} ></Button>
                }
                <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                    Submit
                </Button>
            </Stack>
        </div>
    );
}

export default ExistingPaymentPage