import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import InsurenceReduction from './InsurenceReduction'



const InsurenceIndex = () => {
  return (

    <>
      <Header />
      <InsurenceReduction/>
      <Footer />
    </>
    
  )
}

export default InsurenceIndex
