import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import VictimMain from './VictimMain'





const VictimImpact = () => {
  return (
    <>

    <Header />
    <VictimMain />
    <Footer />
      
    </>
  )
}

export default VictimImpact
