import React from 'react'
import JoshuaLawMain from './JoshuaLawMain'
import Header from '../../components/Header'
import Footer from '../../components/Footer'



const JoshuaLaw = () => {
  return (
    <>

    <Header />
    <JoshuaLawMain />
    <Footer />
      
      
    </>
  )
}

export default JoshuaLaw
