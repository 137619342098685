import React from "react";
import { Box, CardMedia, Container, Grid, Typography, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DljlFaqImg from "../../assets/images/driving-lesson/DljlFaqImg.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";


const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    }
  }
}

const drivingLessonsFaq = [
  {
    question: "Does the Self Paced Driver’s Education course have the same benefits as the Driver’s Education course?",
    answer: "The only difference is that some insurance providers will not accept self-paced driver’s education certificates for possible insurance discounts. Most of them will require a certification from a direct school that was taught by the present instructor.",
  },
  {
    "question": "Do you offer online driver’s education courses?",
    "answer": "Yes, we partnered with Safe2Drive to provide an online driver's course that meets all the Joshua’s Law requirements. Don't worry parents--this online driver's education course isn't the ONLY class option you have when you choose 1 ACT Driving Schools! Visit our driver's education page for more information on the class and registration with 1 ACT Driving Schools. We look forward to working with you!"
  },

  {
    question: "Does the GDEC Scholarship cover the online self-paced driver’s education course?",
    answer: ' No, unfortunately the GDEC Scholarship will not accept the online self-paced option for completion. ',
  },
  {
    question: "Who do I reach out to if I have any questions or issues with my account?",
    answer: 'Just call (800) 763-1297 or email support@safe2drive.com.',
  },

]


const DloFaq = () => {
  const navigate = useNavigate()
  return (
    <>

      <Container>


        <Box sx={{ py: "50px", position: "relative", }}>
          <div className="DrivingLessonsFaq_ani"></div>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft " component="img" alt="faq Image" src={DljlFaqImg} sx={styles.faqRightImage} />
              </Grid>

            </Grid>

            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>

          </Grid>

        </Box>

      </Container>
    </>
  )
}

export default DloFaq;
