import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CeFaqimg from "../../assets/images/ClinicalEvaluation/CeFaqimg.png";


const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
}

const drivingLessonsFaq = [
  {
    question: 'What types of legal situations require a clinical evaluation?',
    answer: ' If you are charged with DUI, Reckless Driving, Possession, or BUI, you may be required to complete an evaluation by court, probation, or your attorney.',
  },
  {
    question: 'Are your clinical evaluations conducted by licensed professionals?',
    answer: 'Choice Counseling Centers are certified to offer State/DHR approved Drug and Alcohol Evaluations for both first-time DUI offenders and Multiple Offenders. Choice Counseling Centers are also approved to facilitate ASAM Level 1 Treatment Classes in several locations.',
  },
  {
    question: "Can I schedule a confidential session for my evaluation?",
    answer: ' This confidential meeting could take up to an hour in the Choice Counseling facilities, with accommodations 7 days a week.',
  },
  {
    question: "What languages are the evaluations available in?",
    answer: ' We are in connection with both English and Spanish evaluators.',
  },
  {
    question: "How long does an evaluation session last?",
    answer: ' Evaluation will last up to one hour.',
  },
  {
    question: "My lawyer told me I need to complete a clinical evaluation. What is that referring to, and do you offer this?",
    answer: ' Clinical Evaluation is a one-on-one session between the DHR approved substance abuse professional and you. If you are charged with DUI, Reckless Driving, Possession, or BUI, you may be required to complete an evaluation by court, probation, or your attorney. This confidential meeting could take up to an hour in the Choice Counseling facilities, with accommodations 7 days a week',
  },

]


const CeFaq = () => {
  return (
    <>

      <Box sx={{ py: "50px", position: "relative", }}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={CeFaqimg} sx={styles.faqRightImage} />
              </div>

            </Grid>
            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}

export default CeFaq
