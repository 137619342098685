import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import tick from "../../assets/images/driving-lesson/tick.png"

import CEBannerLeft from "../../assets/videos/CEBannerRight.png"

import Register from '../Register/Register'
import { useState } from 'react'

const styles = {
  bannerRight:{
      '@media (max-width: 900px)': {
          paddingTop:'30px',
        }
  },
  dlBanner : {
    flexGrow: 1, background:`url(${drivingLesson}) no-repeat top center`,backgroundSize:'100% 100%',padding:'150px 0px 80px',
    '@media (max-width: 900px)': {
      padding:'50px 0px 50px'
    }
  }
}

const CeBanner = () => {

  const [registerStatus, setRegisterStatus] = useState(false)

  return (
    <>

      <Box sx={styles.dlBanner}>
        <Container>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
            <Grid item md={7}>

              <Typography variant="h1" sx={{ color: "var(--yellow) !important", mt:'20px', }}>
                DHR-approved Clinical Evaluations?
              </Typography>

              <Grid sx={{margin:'40px 0px 0px',}}>
                  <Grid sx={{display:'flex',marginBottom:'20px',}}><img src={tick} style={{ width: '20px', height:'20px', margin:'6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important">Evaluations for both First-time DUI offenders and Multiple offenders</Typography></Grid>
                  <Grid sx={{display:'flex',marginBottom:'20px',}}><img src={tick} style={{ width: '20px', height:'20px', margin:'6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important">Approved ASAM Level 1 Treatment Classes </Typography></Grid>
                  {/* <Grid sx={{display:'flex',marginBottom:'20px',}}><img src={tick} style={{ width: '20px', height:'20px', margin:'6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important">Sessions in both English & Spanish</Typography></Grid>
                  <Grid sx={{display:'flex',marginBottom:'20px',}}><img src={tick} style={{ width: '20px', height:'20px', margin:'6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important">Sessions last up to an hour</Typography></Grid> */}
              </Grid>

              {/* <Button variant="outlined" className='greenButAni'  onClick={() => setRegisterStatus(true)}>
                REGISTER
              </Button> */}
              <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus}/>

            </Grid>

            <Grid item md={5}>
                <CardMedia className="" component="img" alt="location Banner image" src={CEBannerLeft} sx= {styles.bannerRight}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
    </>
  )
}

export default CeBanner
