import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'

import footban2 from "../../assets/images/home/footban2.jpg"

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import faqlft from "../../assets/images/home/faq-lft.png";

import gdec from "../../assets/images/home/Scholarship-program.png";

const styles = {

    CancellationPolicyHeder: {
        background: `url(${footban2}) no-repeat top center`, backgroundSize: '100% 100%', padding: '160px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px',
        }
    },

    cancellationHeading: {
        color: 'var(--primary) !important',
    },

    faqRight: {

        '@media (max-width: 900px)': {
            margin: 'auto',

        },
    },
    faqRightImage: {
        width: '80%', marginRight: 'auto', marginY: '30px',
        '@media (max-width: 900px)': {
            width: '100%'
        }
    },
    gdecImages: {
        width: '80%',
        margin: '0px auto 30px',
        border: '1px solid #6d6d6d26',
        borderRadius: '10px',
        boxShadow: '4px 4px 16px #00000014',
    }

}

const GdecInfo = () => {
    return (
        <>

            <Box>

                <Box sx={styles.CancellationPolicyHeder}>
                    <Container>
                        <Typography variant='h6' sx={{ color: '#fff !important', }}>GEORGIA DRIVER’S EDUCATION GRANT</Typography>
                        <Typography variant='h4' sx={{ color: '#fff !important', }}>SCHOLARSHIP PROGRAM</Typography>
                    </Container>
                </Box>

                <Box sx={{ py: "70px", }}>
                    <Container>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>

                            <Grid item md={6}>
                                <CardMedia component="img" src={gdec} sx={styles.gdecImages} />
                            </Grid>

                            <Grid item md={6}>
                                <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)' }}>
                                    <Typography variant='h6' sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Already received your scholarship code?
                                    </Typography>
                                    <Typography component='f14'>If you have already received your code from the <a href='https://www.gahighwaysafety.org/georgia-drivers-education-grant-scholarship/'>GDEC Scholarship Program</a> please call us at 770-274-4223 to present your redemption code.</Typography>
                                </Grid>

                                <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)', mt: '30px', }}>
                                    <Typography variant='h6' sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Don't have a scholarship code yet?
                                    </Typography>
                                    <Typography component='f14'>Please visit <a href='https://www.gahighwaysafety.org/georgia-drivers-education-grant-scholarship/'>GDEC Scholarship Program</a> to apply.



                                    </Typography>
                                    <br />

                                    <Typography sx={{mt:2}} component='f14'>
                                        <b>NOTE: </b>Scholarships must be awarded before a student begins his/her driver’s education course, so please be mindful of this policy if the student/parent choses pay for a course out of pocket and begin the driver’s education course before the application is reconsidered.
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Container>
                </Box>

                <Box className="google_review_bg" sx={{ py: "80px", }}>
                    <Container>
                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>

                            <Grid item md={6} sx={styles.faqRight}>
                                <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={faqlft} sx={styles.faqRightImage} />
                                </Grid>
                            </Grid>

                            <Grid item md={6}>
                                <Typography variant='h3' sx={{ mb: '20px', }}>HOW IT WORKS</Typography>

                                <Grid sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                    <Typography >
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Teen drivers and their parents or guardians apply by completing an online application by visiting &nbsp;
                                        <a href="https://www.gahighwaysafety.org/georgia-drivers-education-grant-scholarship/">www.georgiadrivers.ga.gov.</a>
                                    </Typography>
                                </Grid>

                                <Grid sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                    <Typography>
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Students can apply all month.
                                    </Typography>
                                </Grid>

                                <Grid sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                    <Typography>
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Scholarships are selected on the <b>1st of each month</b> from the previous month's pool of applications. Students and parents are notified by email if the application has been awarded or denied.
                                    </Typography>
                                </Grid>

                                <Grid sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 6px' }}>
                                    <Typography>
                                        <FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp;
                                        Students have 30 days from the date of the scholarship award to present their scholarship redemption code to 1 Act Driving Schools and enroll in a 30-hour plus 6-hours behind the wheel drivers education course. Students have 180 days from the date of the scholarship award to complete the course.
                                    </Typography>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Container>
                </Box>


            </Box>

        </>
    )
}

export default GdecInfo
