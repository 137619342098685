
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Grid, Box, Stack, Typography, FormControlLabel, Checkbox, Button, Card, Divider, TextField } from '@mui/material';
// import FileTypeField from './FileTypeField';
import { base_Url } from '../BaseUrl/ApiUrl';
import axios from 'axios';
import _ from 'lodash'
import { Toaster, toast } from "sonner";


const ExistingDocumentSection = ({ handleBack, coursedetails, setCoursedetails, handleNext, answers, setAnswers, docInfo, setDocInfo }) => {





    const success = (data) => {
        toast.success(data, {
            position: 'top-center',
            style: { backgroundColor: 'green', color: 'white' },

            cancel: {
                label: "close",
                onClick: () => console.log('Cancel!'),
            },
        });
    }

    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },

            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }



    const roadTest = coursedetails.some(course => course.type === 5);
    const questions = [
        { key: 'takenbtwACT', text: "1) Already taken behind the wheels I Act?" },

        // { key: 'takenbtwothers', text: "2) Already taken behind the wheels other than I Act?" },
        { key: 'learnerspermit', text: "2) Do you have a Learner Permit?" },
        { key: 'completedDE', text: "3) Did you complete Driver's Education?" }
    ];

    const handleChange = (questionKey, answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionKey]: answer
        }));
    };

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [name]: value
        }));
    };


    const handleImageChange = (event) => {
        const value = event.target.files[0];

        console.log('value 123', value)
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            ['permitnumber']: value
        }));
    };

    const handleSubmit = () => {
        console.log('answers', answers)
        setCoursedetails(prevDetails => {
            return prevDetails.map(course => ({
                ...course,
                takenbtwACT: _.isNull(answers.takenbtwACT) ? 0 : answers.takenbtwACT,
                takenbtwothers: _.isNull(answers.takenbtwothers) ? 0 : answers.takenbtwothers,
                completedDE: _.isNull(answers.completedDE) ? 0 : answers.completedDE,
                learnerspermit: _.isNull(answers.learnerspermit) ? 0 : answers.learnerspermit,
                DEproofnumber: _.isNull(answers.DEproofnumber) ? 0 : answers.DEproofnumber,
                permitnumber: _.isNull(answers.permitnumber) ? 0 : answers.permitnumber
            }));
        });

        // if (_.isNull(answers.takenbtwACT)) {
        //     toast.error('please answer Already taken behind the wheels I Act');
        //     return;
        // }
        // if (_.isNull(answers.takenbtwothers)) {
        //     failure('please answer Already taken behind the wheels other than I Act');
        //     return;
        // }
        // if (_.isNull(answers.completedDE)) {
        //     failure('please answer Did you complete Drivers Education');
        //     return;
        // }
        // if (_.isNull(answers.learnerspermit)) {
        //     failure('please answer Do you have a Learner Permit');
        //     return;
        // }

        // if (answers.completedDE == 0) {
        //     if (_.isEmpty(answers.DEproofnumber)) {
        //         failure('Drivers education proof No is required ');
        //         return;
        //     }
        // }
        // if (answers.learnerspermit == 0) {
        //     if ((!answers.permitnumber)) {
        //         failure('Permit proof is required ');
        //         return;
        //     }
        // }

        let isValid = true;

        coursedetails?.forEach(product => {
            if (product?.type !== 5 && product.type !== 1 && !product.addonid) {
                failure(`Please select a slot for ${product.productname}`);
                isValid = false;
                return;
            }

            if (product?.type !== 5 && product.type !== 1 && product.type !== 4 && !product?.classmode) {
                failure(`Please select the class mode for ${product.productname}`)
                isValid = false;
                return;
            }


            if (product?.classmode == 2) {
                if (!product?.locationid) {
                    failure(`(for in-person classes): Please select the location ${product.productname}`)
                    isValid = false;
                }
            }
        });
        if (isValid) {
            handleNext(2);
        }
    };

    return (
        <div>
            <Toaster />
            <Grid container spacing={2} sx={{ p: 1 }}>
                {/* <Grid item xs={12} md={6}>
                    <Card>
                        <Typography sx={{ color: 'black !important ', m: 1 }}>Documents</Typography>
                        <Divider />
                        <Box sx={{ px: 2, py: 3 }}>
                            <FileTypeField
                                docInfo={docInfo}
                                setDocInfo={setDocInfo}
                            />
                        </Box>
                    </Card>
                </Grid> */}

                {roadTest && (
                    <Grid item xs={12} >
                        <Card>
                            {/* <Typography sx={{ color: 'black !important ', m: 1 }}>Road Test</Typography>
                            <Divider /> */}
                            <Box sx={{ px: 2, py: 3 }}>
                                <Stack spacing={2}>
                                    {questions.map((question) => {
                                        const { key, text } = question;
                                        return (
                                            <div key={key}>
                                                <Typography>{text}</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={answers[key] === 0}
                                                            onChange={() => handleChange(key, 0)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={answers[key] === 1}
                                                            onChange={() => handleChange(key, 1)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="No"
                                                />
                                                {/* {key === 'completedDE' && answers[key] === 0 && (
                                                    <TextField
                                                        name="DEproofnumber"
                                                        label="DE Proof Number"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={answers.DEproofnumber}
                                                        onChange={handleTextFieldChange}
                                                        size="small"
                                                        sx={{ mt: 1 }}
                                                    />
                                                )} */}
                                                {/* {key === 'learnerspermit' && answers[key] === 0 && (
                                                    <TextField
                                                        name="permitnumber"
                                                        // label="Permit Number"
                                                        variant="outlined"
                                                        type='file'
                                                        fullWidth
                                                        // value={answers.permitnumber}
                                                        onChange={handleImageChange}
                                                        size="small"
                                                        sx={{ mt: 1 }}
                                                    />
                                                )} */}
                                            </div>
                                        );
                                    })}
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                )}
            </Grid>

            <Stack sx={{ m: 2 }} justifyContent="space-between" direction="row">
                {/* <Button variant="contained" onClick={handleBack}>Back</Button> */}
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </Stack>
        </div>
    );
};

export default ExistingDocumentSection;
