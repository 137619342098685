import AWS from 'aws-sdk';

const UploadImage = ({ srcData, folderName }) => {
  const S3_BUCKET = "oneactfilestorage";
  const REGION = "us-east-1";
  const ACCESS_KEY = "AKIA3FRRIVXLXNDI7T5A";
  const SECRET_ACCESS_KEY = "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";

  const FOLDER_NAME = 'student/';
  const ACTIVITY_NAME = 'regidter/';
  const type = 'driving_school/'
  const date = Date.now()

  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
    // endpoint: `https://${REGION}.digitaloceanspaces.com/OneActDriving/studentDashboard`
  });

  const handleUpload = async () => {
    if (srcData) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `${type}${FOLDER_NAME}${ACTIVITY_NAME}${folderName}${date}`,
        Body: srcData,
        // ACL: 'public-read',
        ContentType: srcData.type
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            console.error('Error uploading image:', err);
            reject(err);
          } else {
            console.log('Image uploaded successfully.', data.Location);
            resolve(data.Location);
          }
        });
      });
    } else {
      console.error('No file selected for upload.');
      return null;
    }
  };

  return handleUpload();
};

export default UploadImage;