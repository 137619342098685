
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { IoMdClose } from "react-icons/io";
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack, Typography, DialogTitle, Dialog, DialogContent, IconButton, TextField, Divider,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import NewUserRegister from './NewUserRegister';
import ExistingUserRegister from '../ExistingUser/ExistingUserRegister';
import ForgotPassword from '../../components/Header/ForgotPassword';

import { Toaster, toast } from "sonner";



const success = (data) => {
  toast.success(data, {
    position: 'top-center',
    style: { backgroundColor: 'green', color: 'white' },

    cancel: {
      label: "close",
      onClick: () => console.log('Cancel!'),
    },
  });
}

const failure = (data) => {
  toast.error(data, {
    position: 'top-center',
    style: { border: '1px solid red' },

    cancel: {
      label: "x",
      // style: {
      //     backgroundColor: '#a8261d', 
      //     color: '#ffffff', 
      //     border: 'none', 
      //     padding: '2px 8px',
      //     borderRadius: '4px', 
      // },
      onClick: () => console.log('Cancel!'),
    },

  });
}


const Register = ({ setRegisterStatus, openStatus, data, finalData, classmode, btw, value }) => {


  console.log('btw >>>', btw)
  console.log(finalData?.packages?.map(item => item?.packageid))




  const [newFinalData, setNewFinalData] = useState({})

  const fetchcheckPackageisExist = async (packageid) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: finalData?.packages?.map(item => item?.packageid)

      })
      if (response.data.status) {
        console.log('response', response?.data?.response[0])
        setNewFinalData(response?.data?.response)
      } else {
        setNewFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // if (btw) {
    fetchcheckPackageisExist()
    // }

  }, [finalData])







  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const opensuccesstoast = (message) => {
    enqueueSnackbar(message, {
      variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 2000
    })
  }
  const openfailuretoast = (message) => {
    enqueueSnackbar(message, {
      variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 4000
    })
  }


  const [newUser, setNewUser] = useState(false)
  const [extUser, setExtUser] = useState(false)




  const handleNewUser = () => {
    setNewUser(true)
    setRegisterStatus(false)
  }


  console.log('finalData <<<<<#####3', finalData)
  const studentDetails = JSON.parse(localStorage.getItem('Student'))
  const userid = studentDetails?.customerid


  const [userUpdated, setUserUpdate] = useState(false)

  useEffect(() => {

  }, [userUpdated]);



  const [passwordOpen, setPasswordOpen] = useState(false)


  const [Email, setEmail] = useState(null)
  const handleForgot = async (email) => {

    console.log('email', email)
    setEmail(email)

    try {
      const response = await axios.post(`${base_Url}/forgotPassword`, {
        email: email,
        roleid: 2

      })
      if (response.data.status) {

        success(response.data.message)
        setPasswordOpen(true)
        setRegisterStatus(false)
      }
      else {
        failure(response.data.message)
      }
    } catch (error) {
      console.log('error', error)
    }

  }





  return (
    <>
      <Toaster />

      <ForgotPassword
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        Email={Email}

      />

      {
        userid ?

          <ExistingUserRegister
            openStatus={openStatus}
            setRegisterStatus={setRegisterStatus}
            finalData={newFinalData}
          />

          :
          <NewUserRegister
            openStatus={newUser}
            setRegisterStatus={setNewUser}
            finalData={finalData}
          />


      }




    </>
  );
};

export default Register;