import { Box, CardMedia, Container, Grid, Typography, fabClasses } from "@mui/material";
import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import user from "../../assets/images/home/user.png";
import star from "../../assets/images/home/star.png";
import google from "../../assets/images/home/google.png";
import Review from "./Review";

const googleReview = [
  {
    userImage: user,
    userName: "Simon Arpad",
    userRoll: "Global Accountability Officer",
    userStar: star,
    userdescription:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  },
  {
    userImage: user,
    userName: "John",
    userRoll: "Global Accountability Officer",
    userStar: star,
    userdescription:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  },
  {
    userImage: user,
    userName: "Jack",
    userRoll: "Global Accountability Officer",
    userStar: star,
    userdescription:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  },
  {
    userImage: user,
    userName: "Jack",
    userRoll: "Global Accountability Officer",
    userStar: star,
    userdescription:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  },
];

const HomeGoogleRev = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Box sx={{}} className="google_review_bg">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "black !important" }}>
                Echoes of Excellence
              </Typography>
              <Typography variant="h4" sx={{ color: "black !important", py: 2 }}>
                What Our Students Are Saying
              </Typography>
            </Grid>
          </Grid>

          {/* <div className="slider-container" style={{ marginTop: "50px" }}>
            <Slider {...settings} className="slider">
              {googleReview.map((review, index) => (
                <div key={index} className="slide-item">
                  <Grid className="review_box" item
                    sx={{
                      background: "#fff",
                      padding: "20px",
                      borderRadius: "6px",
                      margin: "0 10px", // Add margin here
                    }}
                  >
                    <Grid
                      className="review_box_top"
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="User Image"
                        src={review.userImage}
                        sx={{ width: "60px" }}
                      />
                      <div className="">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "var(--black)",
                          }}
                        >
                          {review.userName}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#808080 !important",
                            fontSize: "11px !important",
                            marginTop: "6px",
                          }}
                        >
                          {review.userRoll}
                        </Typography>

                        <CardMedia
                          component="img"
                          alt="User Image"
                          src={review.userStar}
                          sx={{ width: "80px", marginTop: "6px" }}
                        />

                      </div>

                      <CardMedia
                        component="img"
                        alt="Google"
                        src={google}
                        sx={{ width: "30px", marginTop: "10px" }}
                      />

                    </Grid>

                    <Typography
                      sx={{
                        marginTop: "16px",
                        color: "var(--black)",
                        fontSize: "12px !important",
                        fontWeight: "400",
                        lineHeight: "1.5 !important",
                      }}
                    >
                      {review.userdescription}
                    </Typography>
                  </Grid>
                </div>
              ))}
            </Slider>
          </div> */}

          <Review />
        </Container>
      </Box>
    </>
  );
};

export default HomeGoogleRev;
