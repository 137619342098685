import React, { useEffect } from 'react'
import Header from '../../components/Header'
import DdlBanner from './DdlBanner'
import Footer from '../../components/Footer'
import DdlFeatures from './DdlFeatures'
import DdlFaq from './DdlFaq'
import DdlHelpForm from './DdlHelpForm'
import HomeGoogleRev from '../Home/HomeGoogleRev'




const DefensiveDrivingLocation = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>
    <Header />
    <DdlBanner />
    <DdlHelpForm />
    <DdlFeatures />
    <HomeGoogleRev />
    <DdlFaq />
    <Footer />
      
    </>
  )
}

export default DefensiveDrivingLocation
