
/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Dialog, Slide, DialogTitle, DialogContent, CardMedia, Tooltip, useMediaQuery, Divider, Chip, IconButton, Grid, Stack, Box, Button, colors, Card, CardHeader, CardContent, TextField, InputLabel } from "@mui/material";
import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
// import {  base_Url } from 'pages/baseUrlLink/apiUrl';
import { base_Url } from "../BaseUrl/ApiUrl";
import _ from 'lodash'

import ExistingCalender from "./ExistingCalender";



import { IoMdCloseCircleOutline } from "react-icons/io";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Margin } from "@mui/icons-material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuCalendarOff } from "react-icons/lu";
import { AiTwotoneClockCircle } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";

import ExistingDocumentSection from "./ExistingDocumentSection";
import ExistingPaymentPage from "./ExistingPaymentPage";


// import DocumentSection from "./Documents";
// import PaymentPage from "./PaymentPage";
import dayjs from 'dayjs';
// import DigitalOcean from "./DigitalOcean";
import { toast, Toaster } from 'sonner';
import { useNavigate } from "react-router-dom";
import { MdLocalOffer } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import UploadImage from "../Register/UploadImage";
import { TiTick } from "react-icons/ti";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const ExistingUserRegister = ({ setRegisterStatus, openStatus, data, finalData, classmode, key, extraData }) => {



    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }
    console.log('praveen', classmode && classmode)

    console.log('suresh', key)

    console.log(`🔥🔥🔥 - `, finalData);





    const [zones, setZones] = useState([])

    const fetchZones = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllBranches`, {

            })
            if (response.data.message) {
                // console.log('response', response?.data?.response)
                const data = response?.data?.response?.map((item) => {
                    return {
                        id: item?.applocationid,
                        label: item?.locationname
                    }
                })

                const newlo = await data?.filter(item => item?.id !== 4)

                console.log('newlo', newlo)
                setZones(newlo)
                // setZones(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchZones()
    }, [])


    console.log('zones', zones)










    const navigate = useNavigate()

    console.log('finalData', finalData)
    const [open, setOpen] = useState(false);



    const [CourseInfo, setCourseInfo] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: data
            })
            if (response.data.message) {
                console.log('response', response?.data?.response)
                setCourseInfo(response?.data?.response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [data])

    console.log('CourseInfo >>>>>', CourseInfo)
    const [coursedetails, setCoursedetails] = useState([])



    const [activeStep, setActiveStep] = useState(0);

    console.log('activeStepraveen', activeStep)

    useEffect(() => {



        const formating = finalData?.packages?.map((item) => {
            return {
                productid: item?.productid,
                productname: item?.productname,
                packageid: item?.packageid,
                type: item?.producttype,
                addonid: extraData?.addonid ? `${extraData?.addonid}` : 0,
                classmode: finalData?.classmode ? `${finalData?.classmode}` : 0,
                locationid: finalData?.locationId ? `${finalData?.locationId}` : 0,
                comboid: finalData?.comboid,
                packageamount: item?.packageamount

            }
        })


        setCoursedetails(formating)


        const isRoadTest = finalData?.packages?.some((item) => item.producttype === 5);

        console.log('isRoadTest', isRoadTest)
        const va = isRoadTest ? 0 : 1
        setActiveStep(va)
    }, [finalData, openStatus])


    console.log('coursedetails', coursedetails)


    const isRoadTest = finalData?.packages?.some((item) => item.producttype === 5);






    const handleNext = (data) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));


    // register form state 

    const [formData, setFormData] = useState(
        {
            firstname: "",
            lastname: "",
            phonenumber: "",
            email: "",
            billingaddress: "",
            billingcity: "",
            billingstate: "",
            zipcode: "",
            Dob: '2004-01-01'
        }
    )


    // road test question state

    const [answers, setAnswers] = useState({
        takenbtwACT: null,
        takenbtwothers: null,
        completedDE: null,
        learnerspermit: null,
        DEproofnumber: '',
        permitnumber: ''
    });



    // documents

    const [docInfo, setDocInfo] = useState([])

    console.log('docInfo', docInfo)


    const fetchDoc = async () => {
        try {
            const response = await axios.post(`${base_Url}/getDocs`, {
                "docfor": 1, // 1-student; 2- instructor; 3- car; 0-none
                "status": 1 // 0-all;1-Active;2-InActive
            })
            if (response.data.message) {
                const initialState = response?.data?.data
                    .filter(document => document.req === "required")
                    .map(document => ({ doctypeid: document.apptypeid, docname: document.typename, doc: null }));
                setDocInfo(initialState)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchDoc()
    }, [finalData])



    // calender function 




    const [selectedCourseInfo, setSelectedCourseInfo] = useState({
        courseid: null,
        type: null,
        combo: null
    })


    console.log('selectedCourseInfo', selectedCourseInfo)



    const onClickCalander = (item) => {
        console.log('item', item)
        // 1-combo    2 -single => based on packages length find it is combo or single 
        setSelectedCourseInfo({
            courseid: item.productid,
            type: item.producttype,
            combo: finalData?.packages.length <= 1 ? 2 : 1
        })
        setOpen(true)
    }

    const totalDuration = finalData?.duration?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);
    const totalpackageamount = finalData?.packageamount?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);

    const handleDrawerClose = () => {

        setAnswers(
            {
                takenbtwACT: null,
                takenbtwothers: null,
                completedDE: null,
                learnerspermit: null,
                DEproofnumber: '',
                permitnumber: ''
            }
        )

        setFormData(
            {
                firstname: "",
                lastname: "",
                phonenumber: "",
                email: "",
                billingaddress: "",
                billingcity: "",
                billingstate: "",
                zipcode: "",
                Dob: dayjs()
            }
        )


        setSelectedCourseInfo(
            {
                courseid: null,
                type: null,
                combo: null
            }
        )

        setDocInfo([])
        // setCoursedetails([])
        // setActiveStep(0)

        setRegisterStatus(false)
    }



    const [loaderopen, setLoaderOpen] = useState(false);

    // payment page
    const [paymentDetails, setPaymentDetails] = useState({
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvvNumber: '',
        cardholderfirstname: "",
        cardholderlastname: "",
        cardzipcode: "",
        billingcountryz: "",
        billingstatez: "",
        billingcityz: "",
        billingaddressz: "",
        billingzipcode: ""
    });





    const userData = JSON.parse(localStorage.getItem('Student'))


    const fetchPrevUser = async () => {
        try {
            const existUserData = await axios.get(`${base_Url}getStudentDataById/${userData?.customerid}`)

            if (existUserData.data?.status) {
                setPaymentDetails((prev) => {
                    return {
                        ...prev,
                        billingcountryz: existUserData.data?.response[0]?.country,
                        billingstatez: existUserData.data?.response[0]?.state,
                        billingcityz: existUserData.data?.response[0]?.city,
                        billingaddressz: existUserData.data?.response[0]?.address,
                        billingzipcode: existUserData.data?.response[0]?.postcode
                    }
                })
            }
            else {
                return
            }
        } catch (error) {
            console.log(`error in fetchPrevUser - `, error);
        }
    }

    useEffect(() => {
        fetchPrevUser()
    }, [])


    // same as prev
    const [selected, setSelected] = useState(false);
    // same as prev

    const onClickSubmit = async () => {

        let isValid = true;

        coursedetails?.forEach(product => {
            if (product?.type !== 5 && product.type !== 1 && !product.addonid) {
                failure(`Please select a slot for ${product.productname}`);
                isValid = false;
                return;
            }


            if (product?.type !== 5 && product.type !== 1 && product.type !== 4 && !product?.classmode) {
                failure(`Please select the class mode for ${product.productname}`)
                isValid = false;
                return;
            }


            if (product?.classmode == 2) {
                if (!product?.locationid) {
                    failure(`(for in-person classes): Please select the location ${product.productname}`)
                    isValid = false;
                }
            }
        });



        if (isValid) {

            setLoaderOpen(true)
            console.log('clicked submit ', coursedetails)

            // const uploadedImages = await Promise.all(DigitalOcean(docInfo));
            // console.log('uploadedimages', uploadedImages);



            // const docs = uploadedImages.map((item) => {
            //     return {
            //         doctypeid: item?.doctypeid,
            //         doc: item.s3Link
            //     }
            // })

            const imageUpload = await UploadImage({ srcData: answers?.permitnumber, folderName: `${answers?.permitnumber?.name} ` });

            console.log('imageUpload ###', imageUpload)

            const coursearray = coursedetails.map((item) => {
                return {
                    "productid": item?.productid,
                    "productname": item?.productname,
                    "packageid": item?.packageid,
                    "addonid": item?.addonid === null ? 0 : item?.addonid,  // the slot choosed
                    "takenbtwACT": item?.takenbtwACT,      // 0-yes;1-no
                    "takenbtwothers": item?.takenbtwothers,
                    "completedDE": item?.completedDE,          // 0-yes;1-no
                    "DEproofnumber": item?.DEproofnumber,
                    "learnerspermit": item?.learnerspermit,       // 0-yes;1-no
                    "permitnumber": !_.isNull(imageUpload) ? imageUpload : 0,
                    "classmode": !_.isEmpty(item?.classmode) ? item?.classmode : 2,
                    "locationid": item?.locationid || 0,
                    "countyid": item?.countyid || 0,
                    "amount": item?.packageamount
                }
            })

            const newOrderDetails = coursedetails?.map((item) => {
                return {
                    "productid": item?.productid,
                    "packageid": item?.packageid,
                    "addonid": item?.addonid === null ? 0 : item?.addonid,  // the slot choosed
                    "comboid": item.comboid,
                    "amount": item?.packageamount
                }
            })
            const studentDetails = JSON.parse(localStorage.getItem('Student'));
            const userid = studentDetails?.customerid;

            let requestedData

            if (extraData && !_.isEmpty(extraData)) {
                console.log('working')

                requestedData = {
                    settingsId: extraData?.policy[0]?.settingsid,
                    orderId: finalData?.orderId,
                    "customerid": userid,
                    "customersettingsid": extraData?.customersettingsid,
                    "addonid": extraData?.addonid,
                    "productid": extraData?.productid,
                    "classmode": Number(coursedetails[0]?.classmode),
                    "locationid": Number(coursedetails[0]?.locationid),
                    "packageid": coursedetails[0]?.packageid,
                    "paymentdetails": {
                        "cardNumber": paymentDetails?.cardNumber,  // credit card
                        "expirationDate": `${paymentDetails?.expiryMonth}${paymentDetails?.expiryYear}`,
                        "cardCode": paymentDetails?.cvvNumber,
                        "amount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice
                    },
                }
            }
            else {
                console.log('Not working')
                requestedData = {

                    "customerid": userid,
                    "courses": coursearray,
                    "orderdetails": newOrderDetails,
                    "paymentdetails": {
                        "cardNumber": paymentDetails?.cardNumber,  // credit card
                        "expirationDate": `${paymentDetails?.expiryMonth}${paymentDetails?.expiryYear}`,
                        "cardCode": paymentDetails?.cvvNumber,
                        "amount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice,
                        "cardholderfirstname": `${paymentDetails?.cardholderfirstname}` || '',
                        "cardholderlastname": `${paymentDetails?.cardholderlastname}` || '',
                        "cardzipcode": `${paymentDetails?.cardzipcode}` || '',
                        "billingcountry": `${paymentDetails?.billingcountryz}` || '',
                        "billingstate": `${paymentDetails?.billingstatez}` || '',
                        "billingcity": `${paymentDetails?.billingcityz}` || '',
                        "billingaddress": `${paymentDetails?.billingaddressz}` || '',
                        "billingzipcode": `${paymentDetails?.cardzipcode}` || ''
                    },
                    "purchasedetails": {
                        "totalamount": finalData?.packages?.reduce((acc, item) => acc + item.packageamount, 0) || 0,
                        "discount": finalData?.discount,
                        "discountamount": (finalData?.finalcost * finalData?.discount) / 100,
                        "payamount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice
                    },

                }
            }




            console.log('requestedData', requestedData)
            let apiurll;

            if (extraData && !_.isEmpty(extraData)) {
                apiurll = 'reschedulefromstudent'
            } else {
                apiurll = 'buyOtherCourse'
            }

            try {
                const response = await axios.post(`${base_Url}/${apiurll}`, requestedData)
                if (response.data.status) {
                    setLoaderOpen(false)
                    toast.success('Purchased Successfully')
                    navigate('/checkout', {
                        state: {
                            data: response?.data?.response
                        }
                    })
                } else {
                    failure(response?.data?.message)
                    setLoaderOpen(false)
                }
            } catch (error) {
                console.log(error)
            }



        }
    }


    const isMobileView = useMediaQuery('(max-width:600px)');


    const getChipcolor = (data) => {
        console.log('item inside chip', data?.packageid)
        const colour = coursedetails?.find(item => item.packageid == data?.packageid)?.addonid
        console.log('colour', colour)
        if (!_.isEmpty(colour)) {
            return 'success'
        } else {
            return 'error'
        }

    }


    const getChipicon = (data) => {
        console.log('item inside chip', data?.packageid)
        const colour = coursedetails?.find(item => item.packageid == data?.packageid)?.addonid
        console.log('colour', colour)
        if (!_.isEmpty(colour)) {
            return 1
        } else {
            return 2
        }

    }


    const selectedBTWHour = finalData?.packages?.find(item => item.producttype == 1)?.duration


    console.log('isRoadTest', isRoadTest)
    let steps
    if (isRoadTest) {
        steps = [
            'Road Test Q/A',
            'Payment',
        ];
    } else {
        steps = [
            'Payment',
        ];
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Toaster />
            <Drawer anchor="right" open={openStatus}

                ModalProps={{ BackdropProps: { invisible: true } }}
                PaperProps={{ sx: { width: isSmallScreen ? '100%' : '80%' } }}
            >

                {/* <Dialog maxWidth='xl' fullWidth open={openStatus}  TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            height: '800px', 
            '@media (max-width: 600px)': {
              height: '800px', 
            },
            '@media (min-width: 600px) and (max-width: 960px)': {
              height: '800px',
            },
            '@media (min-width: 960px)': {
              height: '800px',
            },
          },
        }}

      > */}


                <Stack direction='row' justifyContent='space-between' sx={{ p: 1 }} alignItems={'center'}>
                    <Typography variant="h5" sx={{ color: 'black !important' }}> Checkout</Typography>
                    <IconButton sx={{ fontSize: '30px', color: 'red' }} variant="outlined" color='error' onClick={() => handleDrawerClose()}  >
                        <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                    </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div >
                                <Card sx={{ m: 1 }}>
                                    <Typography sx={{ p: 1 }}>Courses</Typography>
                                </Card>


                                <Card>
                                    {finalData?.packages?.length > 1 && (
                                        <Chip
                                            icon={<BiSolidOffer />}
                                            size="small"
                                            label={"Combo Offer"}
                                            sx={{ mx: 1, mt: 1, p: 1 }}
                                            variant="filled"
                                            color="success"
                                        />
                                    )}

                                    {finalData?.packages?.map((item, index) => (
                                        <Stack
                                            key={index}
                                            sx={{ padding: "10px", borderBottom: "1px solid #E0E0E0" }}
                                            spacing={2}
                                            direction={isMobileView ? "column" : "row"}
                                            alignItems={isMobileView ? "flex-start" : "center"}
                                        >
                                            <Grid container alignItems="center" spacing={2}>
                                                {/* Image Column */}
                                                <Grid item xs={12} sm={2} textAlign={isMobileView ? "center" : "left"}>
                                                    <div
                                                        style={{
                                                            border: "1px solid #80808080",
                                                            borderRadius: "5px",
                                                            width: isMobileView ? "90%" : "90px",  // Adjust width for mobile
                                                            height: "55px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            margin: isMobileView ? "0 auto" : "0",  // Center the image on mobile
                                                        }}
                                                    >
                                                        <img
                                                            src={item?.image}
                                                            alt="product"
                                                            style={{
                                                                objectFit: "contain",
                                                                maxWidth: "100%",
                                                                height: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>

                                                {/* Price Column */}
                                                <Grid item xs={12} sm={2} textAlign={isMobileView ? "center" : "left"}>
                                                    <Stack alignItems={isMobileView ? "center" : "flex-start"}>
                                                        {[{ productType: 4, condition: selectedBTWHour >= 6, comboAmountKey: "combopackageamount1" },
                                                        { productType: 5, condition: selectedBTWHour, comboAmountKey: "combopackageamount1" },
                                                        { productType: 3, condition: selectedBTWHour < 12, comboAmountKey: "combopackageamount1" },
                                                        { productType: 3, condition: selectedBTWHour >= 12, comboAmountKey: "combopackageamount2" }
                                                        ].map(({ productType, condition, comboAmountKey }) =>
                                                            item.producttype === productType && finalData?.packages?.length > 1 && condition ? (
                                                                <Stack justifyContent="center" alignItems="center" key={comboAmountKey}>
                                                                    <Typography component="span" sx={{ fontSize: "25px" }}>
                                                                        <b>${item[comboAmountKey]}</b>
                                                                    </Typography>
                                                                    <Typography component="span" sx={{ textDecoration: "line-through", color: "black" }}>
                                                                        ${item.packageamount}
                                                                    </Typography>
                                                                </Stack>
                                                            ) : null
                                                        )}

                                                        {(![
                                                            { productType: 4, condition: selectedBTWHour >= 6 },
                                                            { productType: 5, condition: selectedBTWHour },
                                                            { productType: 3, condition: selectedBTWHour < 12 },
                                                            { productType: 3, condition: selectedBTWHour >= 12 }
                                                        ].some(({ productType, condition }) => item.producttype === productType && condition)) && (
                                                                <Typography component="span" sx={{ fontSize: "25px" }}>
                                                                    <b>${item.packageamount}</b>
                                                                </Typography>
                                                            )}
                                                    </Stack>
                                                </Grid>

                                                {/* Product & Duration Column */}
                                                <Grid item xs={12} sm={4}>
                                                    <Stack alignItems={isMobileView ? "center" : "flex-start"}>
                                                        <Typography align={isMobileView ? "center" : "left"}>{item.productname}</Typography>
                                                        {item.producttype !== 5 && (
                                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                                <AiTwotoneClockCircle />
                                                                <Typography>{item.duration} Hrs</Typography>
                                                            </Stack>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                {/* Chip Column */}
                                                <Grid item xs={12} sm={4} textAlign={isMobileView ? "center" : "right"}>
                                                    {item?.producttype !== 1 && item?.producttype !== 5 ? (
                                                        <Chip
                                                            icon={getChipicon(item) === 1 ? (
                                                                <TiTick style={{ width: "20px", height: "20px" }} />
                                                            ) : (
                                                                <FaRegCalendarAlt style={{ width: "20px", height: "20px" }} />
                                                            )}
                                                            label={getChipicon(item) === 1 ? "Slot Picked" : "Pick Slot"}
                                                            variant="outlined"
                                                            color={getChipcolor(item)}
                                                            sx={{
                                                                padding: "10px 20px",
                                                                cursor: "pointer",
                                                                fontWeight: "bold",
                                                                width: isMobileView ? "90%" : "auto",  // Make button full-width on mobile
                                                            }}
                                                            onClick={() => onClickCalander(item)}
                                                        />
                                                    ) : (
                                                        <Typography></Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    ))}


                                </Card>



                                <Grid container sx={{ p: 2 }}>

                                    <Grid item xs={12} md={6}>

                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Card  >
                                            {/* <Typography sx={{ px: 3, py: 1 }}>Order summary</Typography> */}
                                            <Divider />
                                            <Stack sx={{ p: 2 }} spacing={1}>
                                                {
                                                    finalData?.packages?.length > 1 &&
                                                    <>

                                                        <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                                                            <Typography sx={{ color: 'gray !important' }}> Actual Total</Typography>

                                                            {/* <Typography>
                                <span style={{ fontWeight: "500", fontSize: "17px", color: "black" }}>
                                  ${finalData?.finalcost}
                                </span>
                              </Typography> */}


                                                            <Typography
                                                                component="span"
                                                                sx={{ textDecoration: 'line-through', color: 'black' }}
                                                            // sx={{
                                                            //   // textAlign: 'center !important', width: '100%',
                                                            //   borderRadius: { xs: '10px', md: '' },
                                                            //   //  boxShadow: { xs: isSticky ? '0px 4px 10px rgba(0, 0, 0, 0.7)' : 'none', md: 'none' },
                                                            //   //   background: { xs: isSticky ? '#fdba74' : 'white', md: 'white', lg: 'white' } 
                                                            // }}
                                                            >


                                                                {
                                                                    finalData?.comboid == 0 ?
                                                                        <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                                                        </span> :
                                                                        <span
                                                                        // style={{
                                                                        //   textDecoration: "line-through",
                                                                        //   fontSize: "20px",
                                                                        //   color: 'gray'
                                                                        // }}
                                                                        >
                                                                            ${finalData?.finalcost}
                                                                        </span>
                                                                }

                                                            </Typography>

                                                        </Stack>

                                                        {/* <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                              <Typography sx={{ color: 'gray !important' }}> {` Discount (${finalData?.discount}%)`}</Typography>

                              <Typography sx={{ color: 'gray !important', mt: 2 }}>
                                {`Discount `}
                                {finalData?.discount && (
                                  <Chip
                                    label={`${finalData?.discount}%`}
                                    color="error"
                                    variant="combined"
                                    sx={{ ml: 1 }}
                                    size="small"
                                  />
                                )}
                              </Typography>
                              <span style={{ fontWeight: "500", fontSize: "17px", color: "black" }}>
                                ${(finalData?.finalcost * finalData?.discount) / 100}
                              </span>
                            </Stack> */}
                                                    </>
                                                }
                                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography sx={{ color: 'gray !important' }} > Discounted Total</Typography>

                                                    <Typography>

                                                        {
                                                            finalData?.comboid == 0 ?
                                                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                                                    ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}
                                                                </span> :
                                                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                                                    ${Math.round(finalData?.discountprice)}
                                                                </span>
                                                        }
                                                    </Typography>

                                                </Stack>
                                            </Stack>

                                        </Card>
                                    </Grid>
                                </Grid>


                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ width: '100%', mt: 0 }}>
                                <Card sx={{ m: 1, p: 1.3 }}>


                                    <Stepper activeStep={activeStep} orientation={isSmallScreen ? 'vertical' : 'horizontal'}>
                                        {steps.map((label, index) => (
                                            <Step key={index}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>

                                </Card>


                                {activeStep == 0 && (
                                    <ExistingDocumentSection
                                        handleNext={handleNext}
                                        handleBack={handleBack}

                                        answers={answers}
                                        setAnswers={setAnswers}

                                        coursedetails={coursedetails}
                                        setCoursedetails={setCoursedetails}

                                        docInfo={docInfo}
                                        setDocInfo={setDocInfo}
                                    />
                                )}

                                {activeStep == 1 && (
                                    <ExistingPaymentPage
                                        handleBack={handleBack}
                                        onClickSubmit={onClickSubmit}
                                        setActiveStep={setActiveStep}
                                        coursedetails={coursedetails}
                                        paymentDetails={paymentDetails}
                                        setPaymentDetails={setPaymentDetails}
                                        formData={formData}
                                        setFormData={setFormData}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />

                                )}
                            </Box>
                        </Grid>
                    </Grid>




                    <ExistingCalender
                        open={open}
                        setOpen={setOpen}
                        selectedCourseInfo={selectedCourseInfo}
                        coursedetails={coursedetails}
                        setCoursedetails={setCoursedetails}
                        zones={zones}

                        extraData={extraData}
                    />

                </DialogContent>


                {/* </Dialog> */}

            </Drawer>
        </>
    );

};



export default ExistingUserRegister;





