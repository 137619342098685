import React, { useState } from 'react'
import { CardMedia, Grid, Stack, Box, Button, colors, Card, CardHeader, CardContent, TextField, InputLabel } from "@mui/material";
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const ExamplePage = () => {

  const [formData, setFormData] = useState ({

    businessname:'',
    number: '',

  })



  const handleChange = (e) => {

    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
    
    console.log(">>>>>>>gowtham", name)
  };


  const handleSumbit = (e) => {
    e.preventDefault();
    console.log('formData',formData)
  }


  return (
    <>

    <Header />

    <Grid sx={{height:'400px',padding:'150px'}}>

    <form >

    <Grid sx={{}}>

      
        <Grid>
        <InputLabel>Enter Name</InputLabel>
          <TextField
            type="text"
            name="businessname"
            value={formData.businessname}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <br></br>
        <InputLabel>phonenumber</InputLabel>
          <TextField
            type="text"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
          />

          <button onClick={(e) => handleSumbit(e)}>submit</button>

    </Grid>
    
    </form>

    </Grid>

    <Footer />


      
    </>
  )
}

export default ExamplePage
